import { gql } from 'apollo-boost';

export const CREATE_ORDER = gql`
  mutation createOrder(
    $customer: CustomerInput!
    $storeId: String!
    $products: [ProductOrderInput!]!
    $memoNote: String
    $deliveryOptionId: String
    $deliveryAddress: OrderAddressInput
    $paymentOptionId: String
  ) {
    createOrder(
      customer: $customer
      storeId: $storeId
      products: $products
      memoNote: $memoNote
      deliveryOptionId: $deliveryOptionId
      deliveryAddress: $deliveryAddress
      paymentOptionId: $paymentOptionId
    ) {
      id
    }
  }
`;
