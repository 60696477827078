import { ApolloClient,ApolloLink,ApolloProvider,InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Box,CircularProgress } from '@material-ui/core';
import { createUploadLink } from 'apollo-upload-client';
import React,{ lazy,Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router,Route,Switch } from 'react-router-dom';
import GenericError from './components/GenericError';
import NavBar from './components/NavBar';
import StoreRoute from './components/StoreRoute';
import CatalogThemeProvider from './components/Theme';
import { CartProvider } from './context/CartContext';
import { GlobalProvider } from './context/GlobalContext';
import { ManagementProvider } from './context/ManagementContext';
import DataDeletion from './screens/DataDeletion';
import NotFound from './screens/NotFound';
import PrivacyPolicy from './screens/PrivacyPolicy';
import RecoverPassword from './screens/RecoverPassword';


const ManagementRoute = lazy(() => import('./components/ManagementRoute'));

const cache = new InMemoryCache();
const linkWithUpload = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authMiddleware = setContext(async (operation) => {
  const token = localStorage.getItem('token');
  const headers = {
    authorization: token,
  };
  return {
    headers,
  };
});
const onErrorLink = onError(({ graphQLErrors }) => {
  if (!graphQLErrors) {
    const errorContainer = document.getElementById('generic-error');
    render(
      <Box mt={{ xs: 7, sm: 8 }}>
        <GenericError defaultMessage />
      </Box>,
      errorContainer
    );
  }
});
const client = new ApolloClient({
  link: ApolloLink.from([onErrorLink, authMiddleware, linkWithUpload]),
  cache,
});

function App() {
  return (
    <ApolloProvider client={client}>
      <GlobalProvider>
        <CatalogThemeProvider>
          <CartProvider>
            <Router>
              <NavBar></NavBar>
              <Switch>
                <Route
                  path="/admin"
                  render={() => (
                    <ManagementProvider>
                      <Suspense
                        fallback={
                          <Box
                            width={'100vw'}
                            height={'100vh'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <ManagementRoute />
                      </Suspense>
                    </ManagementProvider>
                  )}
                />
                <Route exact path="/data-deletion" component={DataDeletion} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/notfound" component={NotFound} />
                <Route
                  path="/recover-password/:hash"
                  component={RecoverPassword}
                />
                <Route path="/:publicId" component={StoreRoute} />
                <Route exact component={NotFound} />
              </Switch>
            </Router>
          </CartProvider>
        </CatalogThemeProvider>
      </GlobalProvider>
    </ApolloProvider>
  );
}

export default App;
