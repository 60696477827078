import {
  createStyles,
  IconButton,
  InputBase,
  makeStyles,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useState } from 'react';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: 32,
    },
    innerInput: {
      textAlign: 'center',
    },
  })
);

interface QuantityControlProps {
  quantity: number;
  onQuantityChange: (newQuantity: number) => void;
  max: number;
}

const QuantityControl = ({
  quantity,
  onQuantityChange,
  max,
}: QuantityControlProps) => {
  const classes = useStyles();
  const [controlQuantity, setControlQuantity] =
    useState<number | null | string>(quantity);
  return (
    <>
      <IconButton
        disabled={quantity === 1}
        onClick={() => {
          const newQuantity = quantity - 1;
          setControlQuantity(newQuantity);
          onQuantityChange(newQuantity);
        }}
      >
        <RemoveIcon />
      </IconButton>
      <InputBase
        value={controlQuantity}
        className={classes.input}
        type="number"
        onChange={(changeEvent) => {
          const value = parseInt(changeEvent.currentTarget.value);
          if (isNaN(value)) {
            setControlQuantity('');
          }
          if (value > 0 && value <= max) {
            setControlQuantity(value);
            onQuantityChange(value);
          }
        }}
        inputProps={{ className: classes.innerInput }}
      ></InputBase>
      <IconButton
        disabled={controlQuantity === max}
        onClick={() => {
          const newQuantity = quantity + 1;
          setControlQuantity(newQuantity);
          onQuantityChange(newQuantity);
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default QuantityControl;
