import { Divider,FormControlLabel,List,ListItem,ListItemText,Radio,RadioGroup } from '@material-ui/core';
import React from 'react';
import { Controller,useFormContext } from 'react-hook-form';

export interface BaseListItem {
  id: string;
  name: string;
  description?: string;
}

export interface RadioListSelectorProps<OptionType extends BaseListItem> {
  options?: OptionType[];
  onOptionChanged?: (option: OptionType) => void;
}

const RadioListSelector = <OptionType extends BaseListItem>({
  options = [],
  onOptionChanged,
}: RadioListSelectorProps<OptionType>) => {
  const { control, getValues } = useFormContext();

  const onChange = (value: string) => {
    const selectedOption = options.find((option) => option.id === value);
    selectedOption && onOptionChanged?.(selectedOption);
  };
  
  return (
    <List>
      <Controller
        defaultValue={getValues('paymentId') || options?.[0]?.id}
        control={control}
        name="paymentId"
        render={(props) => {
          return (
            <RadioGroup
              innerRef={props.ref}
              onChange={(event) => {
                props.onChange(event);
                onChange(event.target.value);
              }}
              value={props.value}
            >
              {options.map((option, index) => {
                return (
                  <span key={option.id}>
                    <ListItem>
                      <FormControlLabel
                        control={<Radio value={option.id} />}
                        label={
                          <>
                            <ListItemText primary={option.name} />
                            <ListItemText secondary={option.description} />
                          </>
                        }
                      />
                    </ListItem>
                    {index < options.length - 1 && <Divider />}
                  </span>
                );
              })}
            </RadioGroup>
          );
        }}
      />
    </List>
  );
};;

export default RadioListSelector;
