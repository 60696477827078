import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import React, { ReactNode, useContext } from 'react';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
interface ThemeProps {
  children: ReactNode;
}

const CatalogThemeProvider = ({ children }: ThemeProps) => {
  const { customTheme } = useContext(MyCatalogGlobalContext);
  const theme = createMuiTheme(customTheme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default CatalogThemeProvider;
