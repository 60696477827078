import { Avatar, Box, Collapse } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
import './header.css';
import SearchInput from './SearchInput';

interface Props {
  loadingHeader: boolean;
  backgroundUrl?: string | null;
  logoUrl?: string | null;
}

const Header: React.FC<Props> = ({
  loadingHeader,
  backgroundUrl,
  logoUrl,
}: Props) => {
  const { search: globalSearch } = useContext(MyCatalogGlobalContext);
  const [showInput, setShowInput] = useState<boolean>(!!globalSearch);
  if (loadingHeader) {
    return (
      <Box className="Header">
        <Skeleton className="Header__rect--loading" variant="rect" height={128}>
          <Skeleton variant="circle" height={72} width={72} />
        </Skeleton>
      </Box>
    );
  }
  return (
    <>
      <SearchInput
        setShowInput={setShowInput}
        showInput={showInput}
      ></SearchInput>

      <Collapse in={!showInput}>
        <Box
          className="Header"
          style={{
            backgroundImage: `url(${backgroundUrl || '/catalog_cover.png'})`,
          }}
        >
          <Avatar
            className="Header__avatar"
            src={logoUrl || 'no-image.png'}
          ></Avatar>
        </Box>
      </Collapse>
    </>
  );
};

export default Header;
