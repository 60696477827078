import {
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import I18n from 'i18n-js';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { currencyOptions as defaultCurrencyOptions } from '../utils/i18n';

export type Address = {
  street: string;
  streetNumber: string;
  city: string;
  state: string;
  zipcode: string;
};

export type DeliveryOption = {
  id: string;
  name: string;
  description: string;
  price: number;
  active: boolean;
  type: 'DELIVERY' | 'PICKUP';
  pickupAddress: Address;
};

export interface DeliverySelectionProps {
  options?: DeliveryOption[];
  currencyOptions?: typeof defaultCurrencyOptions;
  onOptionChanged?: (option: DeliveryOption) => void;
}

const DeliverySelection: FC<DeliverySelectionProps> = ({
  options = [],
  currencyOptions = defaultCurrencyOptions,
  onOptionChanged,
}) => {
  const { control, getValues } = useFormContext();

  const onChange = (value: string) => {
    const selectedOption = options.find((option) => option.id === value);
    selectedOption && onOptionChanged?.(selectedOption);
  };

  return (
    <List>
      <Controller
        defaultValue={getValues('deliveryId') || options?.[0]?.id}
        control={control}
        name="deliveryId"
        render={(props) => {
          return (
            <RadioGroup
              innerRef={props.ref}
              onChange={(event) => {
                props.onChange(event);
                onChange(event.target.value);
              }}
              value={props.value}
            >
              {options.map((option, index) => {
                const inlineAddress = `${option.pickupAddress?.street}, ${
                  option.pickupAddress?.streetNumber
                } - ${option.pickupAddress?.city} - ${
                  option.pickupAddress?.state || ''
                } ${option.pickupAddress?.zipcode || ''}`;
                return (
                  <span key={option.id}>
                    <ListItem>
                      <FormControlLabel
                        control={<Radio value={option.id} />}
                        label={
                          <>
                            <ListItemText
                              primary={option.name}
                              secondary={
                                option.type === 'PICKUP' ? inlineAddress : ''
                              }
                              style={{
                                paddingRight: `${
                                  I18n.toCurrency(option.price, currencyOptions)
                                    .length * 10
                                }px`,
                              }}
                            />

                            <ListItemText
                              secondary={option.description}
                              style={{
                                paddingRight: `${
                                  I18n.toCurrency(option.price, currencyOptions)
                                    .length * 10
                                }px`,
                              }}
                            />
                            <ListItemSecondaryAction>
                              {I18n.toCurrency(option.price, currencyOptions)}
                            </ListItemSecondaryAction>
                          </>
                        }
                      />
                    </ListItem>
                    {index < options.length - 1 && <Divider />}
                  </span>
                );
              })}
            </RadioGroup>
          );
        }}
      />
    </List>
  );
};

export default DeliverySelection;
