import {
  Badge,
  Box,
  createStyles,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
import './search-input.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: '#FFF',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.53)',
      },
      background: 'rgba(0, 0, 0, 0.53)',
      borderRadius: '50%',
      padding: '6px;',
      overflow: 'visible',
    },
    cartIcon: {
      paddingLeft: 8,
    },
    searchIcon: {
      paddingRight: 8,
    },
    button: {
      height: '48px',
      float: 'right',
      '&:hover': {
        background: 'none',
      },
    },
    input: {
      width: '80%',
    },
    badgeAnimation: {
      animation: `$bounce 300ms ${theme.transitions.easing.easeOut}`,
    },
    '@keyframes bounce': {
      '50%': {
        transform: 'scale(1.5)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  })
);

interface SearchInputProps {
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  showInput: boolean;
}

const SearchInput = ({ setShowInput, showInput }: SearchInputProps) => {
  const history = useHistory();
  let { publicId } = useParams();
  const {
    setSearch: setGlobalSearch,
    setSkip,
    search: globalSearch,
    setLoading,
  } = useContext(MyCatalogGlobalContext);
  const { counter, cartEnabled } = useContext(CartContext);
  const [addingItem, setAddingItem] = useState(false);
  useEffect(() => {
    if (counter > 0) {
      setAddingItem(true);
    }
  }, [counter]);
  const classes = useStyles();
  const [search, setSearch] = useState(globalSearch);
  const [timeoutId, setTimeoutId] = useState<number>();

  const triggerSearch = useCallback(
    (query: string) => {
      setSearch(query);
      clearTimeout(timeoutId);
      const id = window.setTimeout(() => {
        setSkip(0);
        setGlobalSearch(query);
      }, 400);
      setTimeoutId(id);
    },
    [setGlobalSearch, setSkip, timeoutId]
  );
  return (
    <div className={`search-input-container ${showInput ? 'in' : ''}`}>
      {showInput && (
        <div>
          <Paper className="search-input-container__paper-area">
            <IconButton
              aria-label="voltar"
              onClick={() => {
                setShowInput(false);
                setSearch('');
                setGlobalSearch('');
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <InputBase
              autoFocus
              className={classes.input}
              value={search}
              type="search"
              placeholder="Nome do produto"
              onChange={(changeEvent) => {
                triggerSearch(changeEvent.currentTarget.value);
              }}
            ></InputBase>
          </Paper>
        </div>
      )}
      {!showInput && (
        <Box mt={1}>
          {cartEnabled && (
            <IconButton
              className={`${classes.button} ${classes.cartIcon}`}
              onClick={() => {
                setLoading(false);
                history.push(`${publicId}/cart`);
              }}
            >
              <Badge badgeContent={counter} color="primary">
                <ShoppingCart
                  className={`${classes.icons} ${
                    addingItem ? classes.badgeAnimation : ''
                  }`}
                  fontSize="small"
                  onAnimationEnd={() => {
                    setAddingItem(false);
                  }}
                />
              </Badge>
            </IconButton>
          )}
          <IconButton
            className={`${classes.button} ${classes.searchIcon}`}
            aria-label="search"
            onClick={() => {
              setShowInput(true);
            }}
          >
            <SearchIcon className={classes.icons} fontSize="small" />
          </IconButton>
        </Box>
      )}
    </div>
  );
};
export default SearchInput;
