import { ColDef, ValueFormatterParams } from '@material-ui/data-grid';
import createNumberMask from '../utils/createNumberMask';
import getDateTime from '../utils/dateUtils';
import i18n from '../utils/i18n';

export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';
export const SELECTED_PUBLIC_ID_KEY = 'selectedPublicId';
export const STORE_COLORS = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#0097a7',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#827717',
  '#ffc107',
  '#ff5722',
  '#795548',
  '#757575',
  '#000000',
];

type Country = {
  code: string;
  label: string;
  phone: string;
  phoneMask: (string | RegExp)[];
  zipCodeMask: (string | RegExp)[];
  languages: string[],
  currencySymbol: string;
  moneyMask: any;
};

export const COUNTRIES: Country[] = [
  {
    code: 'BR',
    label: 'Brazil',
    phone: '55',
    phoneMask: [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    zipCodeMask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    languages: ['pt-BR'],
    currencySymbol: 'BRL',
    moneyMask: createNumberMask({
      decimalSymbol: ',',
      prefix: '',
      decimalLimit: 2,
      requireDecimal: true,
      allowDecimal: true,
      thousandsSeparatorSymbol: '.',
    }),
  },
  {
    code: 'PT',
    label: 'Portugal',
    phone: '351',
    phoneMask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    zipCodeMask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    languages: ['pt', 'pt-PT'],
    currencySymbol: 'EUR',
    moneyMask: [],
  },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    phoneMask: [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    zipCodeMask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    currencySymbol: 'USD',
    languages: ['en', 'en-US'],
    moneyMask: [],
  },
];

export const PRODUCT_COLUMNS: ColDef[] = [
  {
    field: 'title',
    headerName: i18n.t('Name'),
    disableColumnMenu: true,
    flex: 3,
  },
  {
    field: 'oldPrice',
    headerName: i18n.t('Price'),
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'price',
    headerName: i18n.t('Promo price'),
    disableColumnMenu: true,
    flex: 1,
  },
];

export const ORDER_COLUMNS: ColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('Client Name'),
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'quantity',
    headerName: i18n.t('Qty of Products'),
    disableColumnMenu: true,
    width: 150,
  },
  {
    field: 'createdAt',
    headerName: i18n.t('Created at'),
    valueFormatter: (params: ValueFormatterParams) => {
      return getDateTime(params.value as Date);
    },
    disableColumnMenu: true,
    width: 170,
  },
];

export const PRODUCT_IMAGE_LIMIT = {
  UNPAID: 6,
  PAID: 12,
};