import {
  AppBar,
  Badge,
  createStyles,
  Fade,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { MyCatalogGlobalContext, NavigationType } from '../context/GlobalContext';
import Portal from './Portal';
import AlertDialog from './StockAlert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartIcon: {
      marginLeft: 'auto',
      color: '#FFF',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    badgeAnimation: {
      animation: `$bounce 300ms ${theme.transitions.easing.easeOut}`,
    },
    '@keyframes bounce': {
      '50%': {
        transform: 'scale(1.5)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  })
);

const NavBar = () => {
  const { loading, title, navigationType, backRoute, visible } = useContext(
    MyCatalogGlobalContext
  );
  const history = useHistory();
  const classes = useStyles();
  const {
    counter,
    showCart,
    cartEnabled,
    showStockDialog,
    setShowStockDialog,
  } = useContext(CartContext);
  const [addingItem, setAddingItem] = useState(true);
  useEffect(() => {
    if (counter > 0) {
      setAddingItem(true);
    }
  }, [counter]);
  return (
    <Fade in={visible}>
      <div>
        {!loading && (
          <AppBar position="fixed" elevation={2} className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  if (
                    !backRoute ||
                    history.location.pathname.includes('/cart')
                  ) {
                    return history.goBack();
                  }
                  return history.push(backRoute);
                }}
              >
                {navigationType === NavigationType.DRAWER && <MenuIcon />}
                {navigationType === NavigationType.BACK && <ArrowBack />}
                {navigationType === NavigationType.NONE && null}
              </IconButton>
              <Typography variant="h6" noWrap>
                {title || 'Meu catálogo'}
              </Typography>
              {showCart && cartEnabled && (
                <IconButton
                  edge="end"
                  className={classes.cartIcon}
                  onClick={() => {
                    if (history.location.pathname.includes('/product/')) {
                      const catalogId =
                        history.location.pathname.split('/product/')[0];
                      return history.push(`${catalogId}/cart`);
                    }
                    return history.push(`${backRoute}/cart`);
                  }}
                >
                  <Badge
                    badgeContent={counter}
                    color="secondary"
                    className={classes.cartIcon}
                  >
                    <ShoppingCart
                      className={`${
                        addingItem ? classes.badgeAnimation : ''
                      }`}
                      onAnimationEnd={() => {
                        setAddingItem(false);
                      }}
                    />
                  </Badge>
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        )}
        {showStockDialog && (
          <Portal>
            <AlertDialog
              handleClose={() => {
                setShowStockDialog(false);
              }}
            />
          </Portal>
        )}
      </div>
    </Fade>
  );
};
export default NavBar;
