import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Check,
  ChevronLeftSharp,
  ChevronRight,
  CreditCard,
  LocalShipping,
  LocationOn,
  WhatsApp,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import I18n from 'i18n-js';
import {
  GET_DELIVERY_OPTIONS,
  GET_PAYMENT_METHODS,
} from 'my-catalog-shared/graphql/queries';
import {
  createOrder,
  createOrderVariables,
} from 'my-catalog-shared/graphql/types/createOrder';
import { storePaymentServices } from 'my-catalog-shared/graphql/types/storePaymentServices';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AddressForm from '../components/AddressForm';
import CartProducts, { ProductErrors } from '../components/CartProducts';
import ContactForm from '../components/ContactForm';
import DeliverySelection, {
  DeliveryOption,
} from '../components/DeliverySelection';
import RadioListSelector from '../components/PaymentSelection';
import StepperSkeleton from '../components/StepperSkeleton';
import { CartContext } from '../context/CartContext';
import {
  MyCatalogGlobalContext,
  NavigationType,
} from '../context/GlobalContext';
import { PaymentOption } from '../types/product';
import { currencyOptions } from '../utils/i18n';
import { CREATE_ORDER } from '../utils/mutations';

export type Address = {
  street: string;
  streetNumber: string;
  city: string;
  apartmentNumber?: string;
  neighborhood?: string;
  state?: string;
  zipcode?: string;
  __typename?: string;
};

export type CartForm = {
  deliveryId?: string;
  name: string;
  phone?: string;
  email?: string;
  memoNote?: string;
  paymentId?: string;
  address?: Address;
};

const TO_BE_PAID_TYPES = ['PIX', 'MERCADO_PAGO']

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartContainer: {
      marginTop: '68px',
      padding: 0,
    },
    cartContainerDesktop: {
      marginTop: '84px',
      padding: 0,
    },
    input: {
      width: '100%',
    },
    sendOrder: {
      float: 'right',
    },
    totalLine: {
      background: '#eee',
    },
  })
);

const Cart = () => {
  const { t } = useTranslation();
  const [genericErrorMessage, setGenericErrorMessage] = useState('');

  const [createOrder, { loading }] = useMutation<
    createOrder,
    createOrderVariables
  >(CREATE_ORDER, {
    notifyOnNetworkStatusChange: true,
  });
  const { publicId } = useParams<{ publicId: string; storeId: string }>();

  const { cart, setShowCart, clearCart, whatsapp, store } =
    useContext(CartContext);

  const { data, loading: loadingDeliveryOptions } = useQuery(
    GET_DELIVERY_OPTIONS,
    {
      variables: {
        storeId: store?.id,
      },
      skip: !(!!store?.id)
    }
  );

  const { data: paymentMethodsData, loading: loadingPaymentMethods } =
    useQuery<storePaymentServices>(GET_PAYMENT_METHODS, {
      variables: {
        storeId: store?.id,
      },
      skip: !(!!store?.id)
    });

  const paymentOptions = (paymentMethodsData?.storePaymentServices || []).map(
    (paymentMethod): PaymentOption => {
      return {
        id: paymentMethod?.id as string,
        name: t(paymentMethod?.paymentService?.name || ''),
        description: paymentMethod?.description || '',
        type: paymentMethod?.paymentService.type as PaymentOption['type'],
      };
    }
  );

  const [deliveryOption, setDeliveryOption] =
    useState<DeliveryOption | undefined>(undefined);

  const [paymentOption, setPaymentOption] =
    useState<PaymentOption | undefined>();

  const [step, setStep] = useState<number>(0);
  const nextStep = () => {
    formMethods.trigger();
    setStep(step + 1);
  };
  const prevStep = () => {
    formMethods.trigger();
    setStep(step - 1);
  };

  const sortedDeliveryOptions = [...(data?.deliveryOptions || [])].sort(
    (optionA) => (optionA.type === 'PICKUP' ? -1 : 1)
  );

  useEffect(() => {
    if (data?.deliveryOptions.length && step === 1 && !deliveryOption) {
      setDeliveryOption(sortedDeliveryOptions[0]);
    }
    
    if (paymentOptions.length && (step === 1 || (step===0 && !deliveryOption)) && !paymentOption) {
      const defaultService = paymentOptions[0];
      setPaymentOption(defaultService);
    }
    // eslint-disable-next-line
  }, [step, paymentOptions]);

  const hasDelivery = data?.deliveryOptions.length && !loadingDeliveryOptions;

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { setLoading, setTitle, setNavigationType, setVisible, setBackRoute } =
    useContext(MyCatalogGlobalContext);
  setBackRoute(`/${publicId}`);
  setNavigationType(NavigationType.BACK);
  setTitle(t('Cart'));
  setLoading(false);
  setVisible(true);

  const currencyUnit = store?.user?.currency?.symbol || currencyOptions.unit;
  setShowCart(false);
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    formState,
    ...formMethods
  } = useForm<CartForm>({
    mode: 'onChange',
    shouldUnregister: false,
  });

  const memoNoteWatcher = formMethods.watch('memoNote');

  const [productErrors, setProductErrors] = useState<ProductErrors>();
  const total = useMemo(() => {
    if (!cart.products) {
      return 0;
    }
    let total = Object.values(cart.products).reduce(
      (acc: number, product: any) => {
        const { quantity, price, oldPrice } = product;
        return acc + (price || oldPrice) * quantity;
      },
      0
    );
    if (deliveryOption) {
      total += deliveryOption.price;
    }
    return total;
  }, [cart.products, deliveryOption]);

  const formRef = useRef<HTMLFormElement>(null);
  const whatsAppLinkRef = useRef<null | HTMLAnchorElement>(null);

  const whatsAppText = useCallback(() => {
    if (!cart.products) {
      return '';
    }
    let productsLines = Object.values(cart.products)
      .map((product: any) => {
        const finalPrice = product.price || product.oldPrice || 0;
        const finalPriceCurrency = I18n.toCurrency(finalPrice, {
          ...currencyOptions,
          unit: currencyUnit,
        });
        const itemTotalCurrency = I18n.toCurrency(
          product.quantity * finalPrice,
          {
            ...currencyOptions,
            unit: currencyUnit,
          }
        );
        const selectedOptions = Object.values(product.options || {})
          .map((option: any) => {
            return `\n${option.variationName}: ${option.name}`;
          })
          .join('');
        const productLink = `${
          process.env.REACT_APP_WEB_URL
        }/${publicId}/product/${product.originalId || product.id}`;
        return `\n${product.quantity}x ${product.title}(${finalPriceCurrency}) = *${itemTotalCurrency}*${selectedOptions}\n${productLink}\n`;
      })
      .join('');
    const formValues = getValues();

    let addressMessage = '';
    if (hasDelivery) {
      if (deliveryOption) {
        productsLines += `\n${t('Delivery')}: ${
          deliveryOption?.name
        } = *${I18n.toCurrency(deliveryOption?.price, {
          ...currencyOptions,
          unit: currencyUnit,
        })}*\n`;
      }

      if (deliveryOption?.type === 'DELIVERY') {
        addressMessage = `${t('Address')}: ${
          formValues.address?.street || ''
        }, ${formValues.address?.streetNumber || ''} ${
          formValues.address?.apartmentNumber
            ? '-' + formValues.address?.apartmentNumber
            : ''
        } ${formValues.address?.neighborhood || ''} ${
          formValues.address?.city || ''
        } - ${formValues.address?.state || ''} ${
          formValues.address?.zipcode || ''
        }`;
      } else {
        addressMessage = `${deliveryOption?.name}: ${deliveryOption?.pickupAddress.street}, ${deliveryOption?.pickupAddress.streetNumber}`;
      }
    }

    return `${t('Hi, my name is')} ${formValues.name} ${t(
      'and this is my order'
    )}:\n\n${productsLines} \n\n*${t('Total').toUpperCase()} ${I18n.toCurrency(
      total,
      {
        ...currencyOptions,
        unit: currencyUnit,
      }
    )}*\n\n${t('Payment')}: ${t(
      paymentOption?.name || 'To be arranged'
    )}\n\n${t('Additional information')}:\n_${
      memoNoteWatcher || ''
    }${addressMessage}_`;
  }, [
    cart.products,
    currencyUnit,
    getValues,
    publicId,
    t,
    total,
    memoNoteWatcher,
    hasDelivery,
    deliveryOption,
    paymentOption
  ]);
  const products = useMemo(() => {
    return cart.products ? Object.values(cart.products) : [];
  }, [cart.products]);

  if (!cart || !cart.products || !Object.keys(cart.products).length) {
    return (
      <Box p={10}>
        <Typography color="secondary" align="center">
          {t('Your cart is empty')}
        </Typography>
      </Box>
    );
  }
  const whatsappLink = whatsapp
    ? `https://wa.me/${whatsapp.countryPhoneCode}${
        whatsapp.address
      }?text=${encodeURIComponent(whatsAppText())}`
    : '#';

  const onSubmit = (data: CartForm, event: any) => {
    const transparentOrder = TO_BE_PAID_TYPES.includes(paymentOption?.type as string);
    const current = whatsAppLinkRef.current;

    const isIphone = window.navigator.userAgent.includes('iPhone');
    !transparentOrder && isIphone && whatsapp && current?.click();

    createOrder({
      variables: {
        customer: {
          name: data.name,
          phone: data?.phone?.replace(/[^\d]/g, ''),
          email: data.email,
        },
        products: products.map((product: any) => {
          return {
            productId: product.originalId || product.id,
            quantity: product.quantity,
            optionIds: Object.values(product?.options || {}).map(
              (option: any) => option.id
            ),
            stockOptionId: product.stockOptionId,
          };
        }),
        storeId: store?.id || '',
        memoNote: data.memoNote,
        deliveryOptionId: data.deliveryId,
        paymentOptionId: data.paymentId,
        deliveryAddress: data.address
          ? {
              ...data.address,
              neighborhood: data.address.neighborhood as string,
              state: data.address.state as string,
              zipcode: data.address?.zipcode?.replace('-', '') || '',
            }
          : null,
      },
    })
      .then(function (response) {
        const cartCopy = cart;
        clearCart(publicId);
        const orderParams = {
          cart: cartCopy,
          total,
          additionalInfo: data.memoNote,
          name: data.name,
          message: whatsAppText(),
        };
        if (transparentOrder) {
          history.replace(
            `/${publicId}/order-payment/${response.data?.createOrder.id}`,
            orderParams
          );
        } else {
          !isIphone && whatsapp && current?.click();
          history.replace(`/${publicId}/order-confirmation`, orderParams);
        }
      })
      .catch((responseError) => {
        const errors = responseError.graphQLErrors.reduce(
          (acc: ProductErrors, error: any) => {
            return {
              ...acc,
              [error.extensions.productId]: {
                message: t(error.extensions.internalCode),
              },
            };
          },
          {}
        );
        setProductErrors(errors);
        if (responseError?.networkError?.response?.status === 400) {
          setGenericErrorMessage(
            t('Sorry, there is something wrong, please check your data.')
          );
        }
      });
  };

  const hasPayment = !!paymentOption;

  const canCreateOrder =
    (!deliveryOption || deliveryOption?.type === 'PICKUP') &&
    step === 1 &&
    !loadingDeliveryOptions &&
    !hasPayment;

  const stepsLayout: {
    renderStep: () => ReactElement;
    title?: string;
    icon?: React.ReactNode;
  }[] = [
    {
      title: t('Contact information'),
      renderStep() {
        return (
          <ContactForm
            hideAdditionalInfo={hasDelivery || loadingDeliveryOptions}
          />
        );
      },
    },
  ];

  if (hasDelivery) {
    stepsLayout.push({
      title: t('Delivery selection'),
      icon: <LocalShipping />,
      renderStep() {
        return (
          <DeliverySelection
            options={sortedDeliveryOptions}
            currencyOptions={{
              ...currencyOptions,
              unit: currencyUnit,
            }}
            onOptionChanged={setDeliveryOption}
          />
        );
      },
    });
    if (deliveryOption?.type === 'DELIVERY') {
      stepsLayout.push({
        title: t('Address'),
        icon: <LocationOn />,
        renderStep() {
          return <AddressForm />;
        },
      });
    }
  }

  if (hasPayment) {
    stepsLayout.push({
      title: t('Payment selection'),
      icon: <CreditCard />,
      renderStep() {
        return (
          <RadioListSelector
            options={paymentOptions}
            onOptionChanged={setPaymentOption}
          />
        );
      },
    });
  }

  const isLastStep = step === stepsLayout.length - 1;

  const showNextStep =
    (hasDelivery && !isLastStep && !canCreateOrder && !isLastStep) ||
    (hasPayment && !isLastStep);

  const hasToPay = TO_BE_PAID_TYPES.includes(paymentOption?.type as string);

  return (
    <Container
      className={
        isMobile ? classes.cartContainer : classes.cartContainerDesktop
      }
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <CartProducts products={products} errors={productErrors} />
          {deliveryOption && (
            <>
              <Box p={2}>
                <Grid alignItems="center" container justify="space-between">
                  <Box>
                    <Typography color="textSecondary" variant="caption">
                      {t('Delivery')}
                    </Typography>
                    <Typography color="textSecondary">
                      {deliveryOption?.name}
                    </Typography>
                  </Box>
                  <Typography color="textSecondary">
                    {I18n.toCurrency(deliveryOption?.price, {
                      ...currencyOptions,
                      unit: currencyUnit,
                    })}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
          <Paper square elevation={0}>
            <Box p={2} mt={2} className={classes.totalLine}>
              <Grid container justify="space-between" alignItems="center">
                <Typography variant="button" color="textSecondary">
                  {t('Total')}
                </Typography>
                <Typography variant="h6">
                  {I18n.toCurrency(total, {
                    ...currencyOptions,
                    unit: currencyUnit,
                  })}
                </Typography>
              </Grid>
            </Box>
          </Paper>
          <Box mb={2}>
            <Divider></Divider>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {loadingDeliveryOptions || loadingPaymentMethods ? (
            <StepperSkeleton />
          ) : (
            <FormProvider
              getValues={getValues}
              register={register}
              errors={errors}
              formState={formState}
              handleSubmit={handleSubmit}
              {...formMethods}
            >
              <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <Box pr={2} pl={2}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {stepsLayout[step]?.title}
                    </Typography>
                  </Box>
                  {stepsLayout[step]?.renderStep()}
                </Box>
              </form>
            </FormProvider>
          )}

          <Box
            marginX={2}
            pb={2}
            mt={2}
            flexDirection="column"
            alignItems="end"
          >
            {!!genericErrorMessage && (
              <Box mb={2}>
                <Alert severity="error">{genericErrorMessage}</Alert>
              </Box>
            )}
            <Box
              justifyContent={step > 0 ? 'space-between' : 'flex-end'}
              display={'flex'}
            >
              {!!((hasDelivery || hasPayment) && step > 0) && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={prevStep}
                  startIcon={<ChevronLeftSharp />}
                >
                  {t('Back')}
                </Button>
              )}
              {!!showNextStep && (
                <Button
                  disabled={(!formState.isValid || loading) && step !== 1}
                  variant="outlined"
                  color="primary"
                  startIcon={stepsLayout[step + 1]?.icon}
                  endIcon={<ChevronRight />}
                  onClick={nextStep}
                >
                  {stepsLayout[step + 1]?.title}
                </Button>
              )}
              {!showNextStep && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(event) => {
                    if (formRef.current?.requestSubmit) {
                      formRef.current?.requestSubmit();
                    } else {
                      formRef.current?.dispatchEvent(new Event('submit'));
                    }
                  }}
                  className={classes.sendOrder}
                  disabled={(!formState.isValid || loading) && step !== 1}
                  startIcon={whatsapp && !hasToPay ? <WhatsApp /> : <Check />}
                >
                  <span id={whatsapp ? 'send-order' : ''}>
                    {hasToPay ? t('Send order and pay') : t('Place Order')}
                  </span>
                  {loading && (
                    <Box ml={2} display="flex">
                      <CircularProgress size={16} />
                    </Box>
                  )}
                </Button>
              )}
            </Box>
            {!showNextStep && whatsapp && (
              <Box mt={1} style={{ textAlign: 'right' }}>
                <Typography variant="caption" color="textSecondary">
                  {hasToPay ? null : t('A new WhatsApp conversation will be open')}
                </Typography>
              </Box>
            )}
            <a
              ref={whatsAppLinkRef}
              target={whatsappLink !== '#' ? '_blank' : '_self'}
              href={whatsappLink}
              rel="noreferrer"
              style={{ display: 'none' }}
            >
              {t('Send order on whatsapp')}
            </a>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Cart;
