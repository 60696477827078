import { gql, useQuery } from '@apollo/client';
import { Color } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { store } from 'my-catalog-shared/graphql/types/store';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, useParams } from 'react-router';
import { CartContext } from '../context/CartContext';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
import Cart from '../screens/Cart';
import OrderConfirmation from '../screens/OrderConfirmation';
import ProductDetail from '../screens/ProductDetail';
import ProductList from '../screens/ProductList';
import OrderPayment from '../screens/order-payment/OrderPayment';

type UrlParams = {
  publicId: string;
};

const GET_STORE = gql`
  query store($publicId: String!) {
    store(publicId: $publicId) {
      id
      name
      color
      publicId
      cartEnable
      categories {
        id
        description
      }
      logo {
        id
        url
        thumbUrl
      }
      cover {
        url
        largeUrl
      }
      socialMedia {
        id
        type
        address
        countryPhoneCode
      }
      user {
        id
        paid
        currency {
          symbol
          delimiter
          separator
        }
      }
    }
  }
`;

const StoreRoute = () => {
  const { setLoading, setCustomTheme } = useContext(
    MyCatalogGlobalContext
  );
  const { setWhatsapp, loadCart, setStore, cart } =
    useContext(CartContext);
  let { publicId } = useParams<UrlParams>();

  const { data, loading } = useQuery<store>(GET_STORE, {
    variables: {
      publicId,
    },
  });
  setLoading(loading);
  if (data?.store) {
    setStore(data?.store);
  }
  useEffect(() => {
    if (data && data.store) {
      const color: Color = {
        ...blueGrey,
        '500': data.store.color,
      };
      setCustomTheme({
        palette: {
          primary: color,
          secondary: color,
        },
      });
    }
    const whatsAppMedia = data?.store?.socialMedia?.filter(
      (media: any) => media.type === 'WHATSAPP'
    )[0];
    setWhatsapp(
      whatsAppMedia?.address
        ? whatsAppMedia
        : null
    );
  }, [setCustomTheme, data, setWhatsapp]);
  if (!Object.keys(cart).length) {
    loadCart(publicId);
  }
  if (data && !data.store) {
    return <Redirect to="/notfound"/>
  }
  return (
    <>
      <Route exact path="/:publicId" component={ProductList} />
      <Route exact path="/:publicId/cart" component={Cart} />
      <Route exact path="/:publicId/product/:id" component={ProductDetail} />
      <Route
        exact
        path="/:publicId/order-confirmation"
        component={OrderConfirmation}
      />
      <Route
        exact
        path="/:publicId/order-payment/:orderId"
        component={OrderPayment}
      />
    </>
  );
};
export default StoreRoute;
