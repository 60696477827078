import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MyCatalogGlobalContext } from '../context/GlobalContext';

const DataDeletion = () => {
  const { setLoading } = useContext(MyCatalogGlobalContext);
  const { t } = useTranslation();
  setLoading(false);
  return (
    <Box mt={12} pl={2}>
      <p>
        {t(
          'To delete your data please send an email to atendimento@meucatalogo.app'
        )}
      </p>
    </Box>
  );
};

export default DataDeletion;
