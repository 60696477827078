import {
  AppBar,
  Box,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Filter, { Category, FilterProps } from '../components/Filter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    doneIcon: {
      marginLeft: 'auto',
    },
  })
);

const FilterDialog: React.FC<
  FilterProps & { onClose: () => void; isDialogOpen: boolean }
> = ({ categories, onFilterSelected, onClose, isDialogOpen }) => {
  const [dialogCategories, setDialogCategories] =
    useState<Category[]>(categories);
  const { t } = useTranslation();
  useEffect(() => {
    setDialogCategories(categories);
  }, [categories]);
  const classes = useStyles();
  return (
    <Dialog open={isDialogOpen} fullScreen>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="fechar"
            onClick={() => {
              setDialogCategories(categories);
              onClose();
            }}
          >
            <Close />
          </IconButton>
          <Typography variant="h6">{t('Filter products')}</Typography>
          <IconButton
            className={classes.doneIcon}
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={() => {
              onFilterSelected(dialogCategories);
              onClose();
            }}
          >
            <Done />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <Filter
          onFilterSelected={(categories) => {
            setDialogCategories(categories);
          }}
          categories={dialogCategories}
        ></Filter>
      </Box>
    </Dialog>
  );
};

export default FilterDialog;
