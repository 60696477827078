import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Filter from '../components/Filter';
import Header from '../components/Header';
import InfoHeader from '../components/InfoHeader';
import Products from '../components/Products';
import { CartContext } from '../context/CartContext';
import { MyCatalogGlobalContext, NavigationType } from '../context/GlobalContext';
import FilterDialog from './FilterDialog';
import './productList.css';
import { store_store_socialMedia } from 'my-catalog-shared/graphql/types/store';

type UrlParams = {
  publicId: string;
};
const ProductList = () => {
  const trigger = useScrollTrigger({ disableHysteresis: true });
  let { publicId } = useParams<UrlParams>();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('oldPrice_ASC');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const {
    setNavigationType,
    setTitle,
    setVisible,
    setSelectedPage,
    setSkip,
    setSelectedCategories,
    setBackRoute,
    selectedCategories,
    loading
  } = useContext(MyCatalogGlobalContext);
  const { setCartEnabled, store } =
    useContext(CartContext);
  setBackRoute(publicId || '');

  setNavigationType(NavigationType.NONE);

  const categories = useMemo(() => {
    return (
      store?.categories?.map((category: any) => ({
        id: category.id,
        name: category.description,
        checked: false,
      })) || []
    );
  }, [store]);
  const checkedCategories = useMemo(
    () => selectedCategories.filter((category) => category.checked),
    [selectedCategories]
  );
  setCartEnabled(!!store?.cartEnable);
  useEffect(() => {
    if (!selectedCategories.length) {
      setSelectedCategories(categories);
    }
  }, [categories, selectedCategories.length, setSelectedCategories]);

  setTitle(store?.name || '');
  setVisible(trigger);
  return (
    <>
      <Helmet>
        <title>{store?.name}</title>
        <meta property="og:title" content={store?.name} />
        <meta property="og:description" content="Meu catálogo de produtos" />
        <meta property="og:image" content={store?.logo?.url || ''} />
      </Helmet>
      <Header
        loadingHeader={loading}
        backgroundUrl={store?.cover?.largeUrl || store?.cover?.url}
        logoUrl={store?.logo?.thumbUrl || store?.logo?.url}
      ></Header>
      <InfoHeader
        title={store?.name || ''}
        visible={!trigger}
        socialMedia={store?.socialMedia as store_store_socialMedia[]}
        loading={loading}
      ></InfoHeader>
      <Box p={isMobile ? 1 : 2}>
        <Grid container>
          <Hidden xsDown>
            <Grid container item md={2} sm={3}>
              {!loading && categories.length > 0 && (
                <Filter
                  onFilterSelected={(selected) => {
                    setSelectedPage(1);
                    setSkip(0);
                    setSelectedCategories(selected);
                  }}
                  categories={selectedCategories}
                ></Filter>
              )}
            </Grid>
          </Hidden>

          <Grid
            item
            md={categories.length ? 10 : 12}
            sm={9}
            style={{ width: '100%' }}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={2}>
                {!loading && categories.length > 0 && (
                  <Hidden smUp>
                    <IconButton
                      aria-label="Abrir dialog filtrar"
                      onClick={() => {
                        setFilterDialogOpen(true);
                      }}
                    >
                      <Badge
                        badgeContent={checkedCategories.length}
                        color="primary"
                      >
                        <FilterList/>
                        <Box ml={1}><Typography>{t('Filter')}</Typography></Box>
                      </Badge>
                    </IconButton>
                    <FilterDialog
                      onClose={() => {
                        setFilterDialogOpen(false);
                      }}
                      isDialogOpen={filterDialogOpen}
                      onFilterSelected={(selected) => {
                        setSelectedPage(1);
                        setSkip(0);
                        setSelectedCategories(selected);
                      }}
                      categories={selectedCategories}
                    ></FilterDialog>
                  </Hidden>
                )}
              </Grid>

              <Grid item xs={5} xl={2}>
                <FormControl
                  variant="filled"
                  className="ProductList--selectOrder"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    {t('Order by')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={orderBy}
                    onChange={(ev) => {
                      setOrderBy(ev.target.value as string);
                      setSelectedPage(1);
                      setSkip(0);
                    }}
                  >
                    <MenuItem value="oldPrice_ASC">{t('Lower price')}</MenuItem>
                    <MenuItem value="oldPrice_DESC">
                      {t('Higher price')}
                    </MenuItem>
                    <MenuItem value="title_ASC">
                      {t('Alphabetically(A-Z)')}
                    </MenuItem>
                    <MenuItem value="title_DESC">
                      {t('Alphabetically(Z-A)')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {!loading && (
                  <Products
                    showPagination={!!store?.user.paid}
                    orderBy={orderBy}
                    categoriesToFilter={checkedCategories}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default React.memo(ProductList, () => true);
