import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: 16,
    },
  };
});

const LabeledValue = ({
  label,
  labelVariant,
  value,
  valueVariant,
  textStyle,
}: {
  label: string;
  labelVariant?: any;
  value: string;
  valueVariant?: any;
  textStyle?: CSSProperties;
}) => {
  const classes = useStyles();
  if (!value) return null;
  return (
    <Grid container className={classes.container} direction="column">
      <Typography variant={labelVariant} color="textSecondary">
        {label}
      </Typography>
      <Typography variant={valueVariant} style={textStyle}>
        {value}
      </Typography>
    </Grid>
  );
};
LabeledValue.defaultProps = {
  labelVariant: 'caption',
  valueVariant: 'subtitle2'

};
export default LabeledValue;
