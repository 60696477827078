import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
  Theme,
} from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import { COUNTRIES } from '../constants';
import { CartContext } from '../context/CartContext';

export interface ContactFormProps {
  hideAdditionalInfo?: boolean;
}

const ContactForm: FC<ContactFormProps> = ({ hideAdditionalInfo = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { whatsapp } = useContext(CartContext);

  const { errors, register, getValues } = useFormContext();

  const phoneMask =
    COUNTRIES.find((country) => country.phone === whatsapp?.countryPhoneCode)
      ?.phoneMask || false;

  const defaults = getValues();

  return (
    <Box marginX={2}>
      <Box mb={2} mt={2}>
        <TextField
          defaultValue={defaults.name}
          className={classes.input}
          error={!!errors.name}
          helperText={errors.name && (errors?.name as any).message}
          inputRef={register({
            required: t<string>('Your name is required'),
          })}
          label={t('Name') + ' *'}
          variant="outlined"
          name="name"
        />
      </Box>
      <Box mb={2}>
        <TextField
          defaultValue={defaults.email}
          className={classes.input}
          error={!!errors.email}
          helperText={errors.email && (errors?.email as any).message}
          inputRef={register({
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('invalid email'),
            },
          })}
          label="Email"
          variant="outlined"
          type="email"
          name="email"
        />
      </Box>
      <Box mb={2}>
        <FormControl variant="outlined" className={classes.input}>
          <InputLabel htmlFor="phone">{t('Phone number')}</InputLabel>
          <OutlinedInput
            label={t('Phone number')}
            inputRef={register}
            name="phone"
            id="phone"
            inputComponent={(props) => {
              const { inputRef, ...other } = props;
              return (
                <MaskedInput
                  {...other}
                  ref={(ref: any) => {
                    inputRef(ref ? ref.inputElement : null);
                  }}
                  defaultValue={getValues('phone')}
                  mask={phoneMask}
                ></MaskedInput>
              );
            }}
          />
        </FormControl>
      </Box>
      {!hideAdditionalInfo && (
        <TextField
          defaultValue={defaults.memoNote}
          className={classes.input}
          label={t('Additional information')}
          name="memoNote"
          inputRef={register}
          placeholder={t('Any additional information')}
          multiline
          rows={4}
          variant="outlined"
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartContainer: {
      marginTop: '68px',
      padding: 0,
    },
    cartContainerDesktop: {
      marginTop: '84px',
      padding: 0,
    },
    input: {
      width: '100%',
    },
    sendOrder: {
      float: 'right',
    },
    totalLine: {
      background: '#eee',
    },
  })
);

export default ContactForm;
