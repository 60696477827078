import {
  Box,
  CardMedia,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import I18n from 'i18n-js';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { currencyOptions } from '../utils/i18n';
import { resolveThumbUrl } from '../utils/resolveImageUrl';
import QuantityControl from './QuantityControl';

export type ProductErrors = { [key: string]: { message: string } };
interface Props {
  products: any[];
  errors?: ProductErrors;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      maxHeight: 48,
    },
    media: {
      width: 96,
      height: 109,
      margin: 'auto',
      marginRight: 0,
    },
    deletButton: {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    productTotal: {},
    outOfStock: {
      background: theme.palette.grey[100],
      '& .MuiCardMedia-root':{
        opacity: 0.5,
        filter: 'grayscale(1)',
      }
    }
  })
);

const CartProducts = ({ products, errors }: Props) => {
  const { changeQuantity, removeItem, store } = useContext(CartContext);
  const { publicId } = useParams<{ publicId: string }>();
  const classes = useStyles();
  return (
    <>
      {products.map(
        ({
          id,
          title,
          gallery,
          quantity,
          oldPrice,
          price,
          options,
          stock,
          originalId
        }: any) => {
          const finalPrice = price || oldPrice;
          return (
            <Box key={id} mb={2}>
              <Paper square elevation={0} className={errors?.[originalId]? classes.outOfStock:''}>
                <Box>
                  <Grid container direction="row">
                    <Grid item xs={3} md={2}>
                      <CardMedia
                        className={classes.media}
                        image={
                          gallery &&
                          gallery.images.length &&
                          resolveThumbUrl(gallery.images[0])
                        }
                      />
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <Grid container>
                        <Grid item xs={10}>
                          <Box p={1} pl={2}>
                            <Typography variant="body1">{title}</Typography>
                            {errors?.[originalId] && (
                              <Typography variant="caption" color="error">
                                {errors?.[originalId].message}
                              </Typography>
                            )}
                            {options &&
                              Object.values(options).map((option: any) => {
                                return (
                                  <Box key={option.id}>
                                    <Typography variant="caption">
                                      {option.variationName}: {option.name}
                                    </Typography>
                                  </Box>
                                );
                              })}
                          </Box>
                          <Box p={0} pl={2}>
                            <Typography variant="caption" color="textSecondary">
                              {I18n.toCurrency(finalPrice, {
                                ...currencyOptions,
                                unit:
                                  store?.user?.currency?.symbol ||
                                  currencyOptions.unit,
                              })}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item className={classes.deletButton} xs={2}>
                          <IconButton
                            className={classes.deleteButton}
                            onClick={() => {
                              removeItem(id, publicId);
                            }}
                          >
                            <Delete fontSize="small"></Delete>
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Box>
                            <QuantityControl
                              max={stock || Infinity}
                              quantity={quantity}
                              onQuantityChange={(newQuantity) => {
                                changeQuantity(id, newQuantity, publicId);
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="center">
                            <Box ml={2} mr={2}>
                              <Typography
                                variant="body2"
                                className={classes.productTotal}
                              >
                                {I18n.toCurrency(quantity * finalPrice, {
                                  ...currencyOptions,
                                  unit:
                                    store?.user?.currency?.symbol ||
                                    currencyOptions.unit,
                                })}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          );
        }
      )}
    </>
  );
};

export default CartProducts;
