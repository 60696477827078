import { Box, Button, Container, Theme, Typography } from '@material-ui/core';
import { Check, WhatsApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router';
import { CartContext } from '../context/CartContext';
import { MyCatalogGlobalContext, NavigationType } from '../context/GlobalContext';
import { isIos } from '../utils/detect-platform';

const useStyles = makeStyles((theme: Theme) => {
  return {
    icon: {
      width: 116,
      height: 116,
      color: theme.palette.success.main,
    },
  };
});

const OrderConfirmation = () => {
  const classes = useStyles();
  const history = useHistory<{ message: string }>();

  const { publicId } = useParams<{ publicId: string }>();
  const whatsAppLink = useRef<null | HTMLAnchorElement>(null);
  const { t } = useTranslation();
  const {
    setLoading,
    setTitle,
    setNavigationType,
    setVisible,
    setShouldRefetch,
  } = useContext(MyCatalogGlobalContext);
  const { setShowCart, whatsapp } = useContext(CartContext);

  setLoading(false);
  setNavigationType(NavigationType.BACK);
  setShowCart(false);
  setTitle(t('Order Sent'));
  setVisible(true);
  const whatsAppText = useCallback(() => {
    if (!history.location.state) {
      return '';
    }
    const { message } = history.location.state;
    return message;
  }, [history.location.state]);
  if (!history.location.state) {
    return <Redirect to={`/${publicId}`} />;
  }
  return (
    <Container maxWidth="sm">
      <Box
        mt={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Check fontSize="large" className={classes.icon} color="secondary" />
        <Typography variant="h6">{t('Your order was sent!')}</Typography>

        <Box
          mt={2}
          mb={2}
          fontStyle="body1"
          color="text.secondary"
          textAlign="center"
        >
          {whatsapp &&
            t(
              'The store already received your order. You can also send a message to their whatsapp to negociate futher details.'
            )}
          {!whatsapp &&
            t(
              'Your order has been placed successufly. Now our store will take care of it. Thank you for buying from us.'
            )}
        </Box>
        {whatsapp && (
          <Box pt={2}>
            <Button
              type="submit"
              color="primary"
              innerRef={whatsAppLink}
              onClick={() => {
                history.goBack();
                setShouldRefetch(true);
              }}
              variant="outlined"
              startIcon={<WhatsApp />}
              target={isIos() ? '' : '_blank'}
              href={`https://wa.me/${whatsapp.countryPhoneCode}${
                whatsapp.address
              }?text=${encodeURIComponent(whatsAppText())}`}
            >
              {t('Resend the WhatsApp message')}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};
export default OrderConfirmation;
