import { Alert } from '@material-ui/lab';
import { GraphQLError } from 'graphql';
import React from 'react';

type GenericErrorProps = {
  error?: readonly GraphQLError[];
  defaultMessage?: boolean;
};

const GenericError = ({ error, defaultMessage }: GenericErrorProps) => {
  const errorMessage = defaultMessage ? '' : error?.[0].message;
  let message = '';
  if (!error && !defaultMessage) {
    return null;
  }
  switch (errorMessage) {
    case 'jwt expired':
      message =
        'Tempo para recuperação de senha excedido. Tente novamente através do app.';
      break;
    case 'invalid token':
      message =
        'Código de recuração de senha inválido. Tente novamente através do app.';
      break;
    default:
      message = 'Desculpe houve um problema. Tente novamente mais tarde.';
  }
  return <Alert severity="error">{message}</Alert>;
};

export default GenericError;
