import { Box,Card,CardContent,CardMedia,Theme,Typography, makeStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { initMercadoPago,Wallet } from '@mercadopago/sdk-react';
import React,{ FC,useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface PaymentTypeMercadoPagoProps {
  amount: string;
  publicKey?: string;
  preferenceId?: string;
}

const PaymentTypeMercadoPago: FC<PaymentTypeMercadoPagoProps> = ({
  publicKey = '',
  preferenceId = '',
  amount,
}) => {
  
  const params = new URLSearchParams(useLocation().search);
  
  const classes = useStyles()

  const paid =  !!params.get('paid') ;

  useEffect(() => {
    initMercadoPago(publicKey);
  }, [publicKey]);

  return (
    <Card variant="outlined" style={{ width: '100%' }}>
      <CardContent>
        <CardMedia
          component="img"
          alt="Mercado pago logo"
          height="76"
          style={{ objectFit: 'contain' }}
          src="/logo_mercado_pago.png"
        />
        <Box mt={6} display="flex" justifyContent="center">
          <Typography variant="body1" color="textSecondary">
            {amount}
          </Typography>
        </Box>
        <Box mb={5} borderRadius={6} height={67} display="flex" justifyContent="center">
            {paid ? <Check fontSize="large" className={classes.icon} color="secondary" /> : 
            <Wallet
              initialization={{ preferenceId }}
              customization={{ texts: { valueProp: 'smart_option' } }}
            />}
          
        </Box>
      </CardContent>
    </Card>
  );
};


const useStyles = makeStyles((theme: Theme) => {
  return {
    icon: {
      width: 116,
      height: 116,
      color: theme.palette.success.main,
    },
  };
});

export default PaymentTypeMercadoPago;
