import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useClipboard from '../utils/hooks/useClipboard';
import { Check } from '@material-ui/icons';

export interface PaymentTypePixProps {
  amount: string;
  pixPayload: string;
}

const PaymentTypePix: FC<PaymentTypePixProps> = ({ pixPayload, amount }) => {
  const { t } = useTranslation();
  const { copy, hasCopied } = useClipboard();
  return (
    <Card variant="outlined" style={{ width: '100%' }}>
      <CardContent>
        <CardMedia
          component="img"
          alt="Pix logo"
          height="76"
          style={{ objectFit: 'contain' }}
          src="/logo_pix.png"
        />
        <Box p={2} mt={2} mb={2} bgcolor={'#eee'} borderRadius={6}>
          <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
            {pixPayload}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {amount}
        </Typography>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            style={{ justifySelf: 'flex-end' }}
            endIcon={hasCopied ? <Check/> : null}
            onClick={() => {
              copy(pixPayload);
            }}
          >
            {hasCopied ? t('Pix copied') : t('Copy PIX')}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default PaymentTypePix;
