const getDateTime = (isoDate: any) => {
  const dateObject =  new Date(isoDate)
  const currentHours = dateObject.getHours()
  dateObject.setHours(currentHours + ((new Date().getTimezoneOffset()*-1)/60))
  const dateWithHours = dateObject.toISOString()
  const splittedTime = dateWithHours.split('T')[1].split(':')
  const time = `${splittedTime[0]}:${splittedTime[1]}`
  const date = dateWithHours.split('T')[0].split('-').reverse().join('/')
  return `${date} - ${time}`;
  }

  export default getDateTime;