import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query orders {
    orders {
      id
      memoNote
      createdAt
      customer {
        id
        name
        phone
        email
        document
        address
      }
      products {
        id
        quantity
        product {
          id
          title
          price
          oldPrice
          gallery {
            images {
              id
              url
            }
          }
        }
        options {
          id
          name
          variation {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ORDERS_REDUCED = gql`
  query orderList {
    orderList {
      id
      memoNote
      createdAt
      customer {
        id
        name
        phone
      }
      products {
        id
        quantity
        title
        description
        oldPrice
        price
        options {
          id
          name
          variation {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ORDER = gql`
  query order($id: String!) {
    order(id: $id) {
      id
      products {
        id
        quantity
        title
        description
        price
        oldPrice
        fromId
        options {
          id
          name
          variation {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ORDER_PAYMENT = gql`
  query orderPayment($orderId: String!) {
    orderPayment(orderId: $orderId) {
      pixPayload
      amount
      customerName
      paymentOption {
        paymentService {
          name
          type
        }
      }
      mercadoPago {
        publicKey
        preferenceId
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      description
    }
  }
`;

export const GET_SUBSCRIPTION_STATUS = gql`
  query subscriptionStatus {
    subscriptionStatus {
      isActive
      addedProductsSize
      productLimit
      hasPurchases
    }
  }
`;
export const GET_SUBSCRIPTION_DETAIL = gql`
  query subscriptionDetail {
    subscriptionDetail {
      id
      active
      manageUrl
      cardId
      createdAt
      remainingDaysToPay
      status
      planName
    }
  }
`;

export const GET_VARIATIONS = gql`
  query variations {
    variations {
      id
      name
      type
      options {
        id
        name
      }
    }
  }
`;
export const GET_OPTIONS = gql`
  query options($variationId: String!) {
    options(variationId: $variationId) {
      id
      name
    }
  }
`;
export const GET_SELECTED_VARIATIONS = gql`
  query selectedVariations($variationIds: [String]!, $optionIds: [String]!) {
    selectedVariations(variationIds: $variationIds, optionIds: $optionIds) {
      id
      name
      options {
        id
        name
      }
    }
  }
`;
export const GET_OPTION_STOCK = gql`
  query optionStockList($optionStockIds: [String]!) {
    optionStockList(optionStockIds: $optionStockIds) {
      id
      option {
        id
        name
        variation {
          id
        }
      }
      quantity
    }
  }
`;
export const GET_QR_CODE = gql`
  query storeQr($publicId: String!) {
    storeQr(publicId: $publicId) {
      id
      url
    }
  }
`;

export const GET_PRODUCT = gql`
  query product($id: String!) {
    product(id: $id) {
      id
      title
      description
      price
      oldPrice
      available
      quantity
      category {
        id
        description
      }
      variations {
        id
        options {
          id
        }
      }
      optionStock {
        id
      }
      selectedOptions {
        id
      }
      gallery {
        images {
          id
          url
        }
      }
    }
  }
`;

export const GET_CURRENCIES = gql`
  query currencies {
    currencies {
      id
      symbol
      code
    }
  }
`;

export const GET_STORE = gql`
  query store($publicId: String!) {
    store(publicId: $publicId) {
      id
      name
      color
      publicId
      cartEnable
      categories {
        id
        description
      }
      logo {
        id
        url
        thumbUrl
      }
      cover {
        url
        largeUrl
      }
      socialMedia {
        id
        type
        address
        countryPhoneCode
      }
      user {
        id
        paid
        currency {
          symbol
          delimiter
          separator
        }
      }
    }
  }
`;
export const GET_STORES = gql`
  query stores {
    stores {
      id
      name
      color
      publicId
      cartEnable
      logo {
        url
      }
      cover {
        url
      }
      socialMedia {
        type
        address
      }
    }
  }
`;
export const GET_PRODUCTS = gql`
  query products(
    $storeId: String!
    $skip: Int!
    $first: Int!
    $search: String
    $orderBy: String!
  ) {
    productList(
      publicStoreId: $storeId
      orderBy: $orderBy
      search: $search
      skip: $skip
      first: $first
    ) {
      total
      products {
        id
        title
        price
        oldPrice
        available
        gallery {
          images {
            id
            url
          }
        }
      }
    }
  }
`;

export const GET_DELIVERY_OPTIONS = gql`
  query deliveryOptions($storeId: String!) {
    deliveryOptions(storeId: $storeId) {
      id
      name
      description
      price
      active
      type
      pickupAddress {
        street
        streetNumber
        city
        state
        zipcode
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query storePaymentServices($storeId: String!) {
    storePaymentServices(storeId: $storeId) {
      id
      description
      deleted
      paymentService {
        id
        name
        description
        type
        logo {
          url
        }
      }
      pix {
        id
        key
        type
      }
    }
  }
`;

export const FIND_ADDRESS = gql`
  query findAddress($zipcode: String!) {
    findAddress(zipcode: $zipcode) {
      zipcode
      street
      neighborhood
      city
      state
    }
  }
`;

export const GET_USER_STORES = gql`
  query storesByUser($email: String!) {
    storesByUser(email: $email) {
      id,
      name,
      publicId,
      deleted
    }
  }
`;
export const GET_USER = gql`
  query user($email: String!) {
    user(email: $email) {
      id,
      name,
      email,
    }
  }
`;