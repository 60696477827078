import { store_store, store_store_socialMedia } from 'my-catalog-shared/graphql/types/store';
import React, { createContext, useEffect, useState } from 'react';
import { ProviderProps } from './GlobalContext';

interface ContextTypes {
  addProduct: (product: any, storeId: string | undefined) => void;
  changeQuantity: (
    id: string,
    quantity: number,
    storeId: string | undefined
  ) => void;
  removeItem: (id: string, storeId: string | undefined) => void;
  setWhatsapp: (socialMedia: store_store_socialMedia | null) => void;
  setShowCart: (show: boolean) => void;
  setCartEnabled: (show: boolean) => void;
  clearCart: (storeId: string | undefined) => void;
  loadCart: (storeId: string | undefined) => void;
  setStore: (store: store_store) => void;
  setShowStockDialog: (show: boolean) => void;
  showCart: boolean;
  cartEnabled: boolean;
  counter: number;
  cart: any;
  whatsapp: store_store_socialMedia | null;
  store: store_store | null;
  showStockDialog: boolean;
}

const sumQuantity = (products: any) => {
  return Object.values(products).reduce<number>((acc, product: any) => {
    return acc + product.quantity;
  }, 0);
};

export const CartContext = createContext<ContextTypes>({
  addProduct: () => {},
  changeQuantity: () => {},
  removeItem: () => {},
  setShowCart: () => {},
  setCartEnabled: () => {},
  setWhatsapp: () => {},
  clearCart: () => {},
  loadCart: () => {},
  setStore: () => {},
  setShowStockDialog: () => {},
  showCart: false,
  cartEnabled: true,
  counter: 0,
  cart: {},
  whatsapp: null,
  store: null,
  showStockDialog: false,
});
export const CartProvider = ({ children }: ProviderProps) => {
  const [counter, setCounter] = useState<number>(0);
  const [stateCart, setStateCart] = useState<any>({});
  const [showCart, setShowCart] = useState<boolean>(false);
  const [whatsapp, setWhatsapp] = useState<store_store_socialMedia | null>(null);
  const [cartEnabled, setCartEnabled] = useState<boolean>(true);
  const [showStockDialog, setShowStockDialog] = useState<boolean>(false);
  const [store, setStore] = useState<store_store | null>(null);

  const loadCart = (storeId: string = 'cart') => {
    const localSorageCart = localStorage.getItem(storeId);
    const cart: any = localSorageCart
      ? JSON.parse(localSorageCart)
      : { products: {} };
    setStateCart(cart);
  };

  useEffect(() => {
    if (!stateCart.products) return;
    const quantity = sumQuantity(stateCart.products);
    setCounter(quantity);
  }, [stateCart.products]);
  const isGlobalStockAvailable = (product: any, cart: any) => {
    if (cart.products) {
      const filteredProducts = Object.values(cart.products).filter(
        (prod: any) =>
          prod.originalId === product.originalId && !prod.stockOptionId
      );
      const total: number = filteredProducts.reduce<number>(
        (acc, product: any) => {
          return acc + product.quantity;
        },
        0
      );
      return total < product.stock;
    }
    return true;
  };
  const addToCart = (product: any, storeId: string = 'cart') => {
    if (product.stock !== null) {
      if (
        product.stock <= 0 ||
        (stateCart.products[product.id] &&
          stateCart.products[product.id].quantity + 1) > product.stock ||
        (!product.stockOptionId && !isGlobalStockAvailable(product, stateCart))
      ) {
        return setShowStockDialog(true);
      }
    }
    const newCart = {
      products: {
        ...stateCart.products,
        [product.id]: {
          ...product,
          quantity: stateCart.products[product.id]
            ? stateCart.products[product.id].quantity + 1
            : 1,
        },
      },
    };
    setCounter(sumQuantity(newCart.products));
    setStateCart(newCart);
    localStorage.setItem(storeId, JSON.stringify(newCart));
  };
  const changeQuantity = (
    id: string,
    quantity: number,
    storeId: string = 'cart'
  ) => {
    const cart = {
      ...stateCart,
      products: {
        ...stateCart.products,
        [id]: {
          ...stateCart.products[id],
          quantity: quantity,
        },
      },
    };
    setCounter(sumQuantity(cart.products));
    setStateCart(cart);
    localStorage.setItem(storeId, JSON.stringify(cart));
  };
  const removeItem = (id: string, storeId: string = 'cart') => {
    let products = { ...stateCart.products };
    delete products[id];
    setStateCart({
      ...stateCart,
      products,
    });
    setCounter(sumQuantity(products));
    localStorage.setItem(
      storeId,
      JSON.stringify({
        ...stateCart,
        products,
      })
    );
  };
  const clearCart = (storeId: string = 'cart') => {
    setStateCart({ products: {} });
    localStorage.removeItem(storeId);
  };

  return (
    <CartContext.Provider
      value={{
        addProduct: addToCart,
        changeQuantity,
        removeItem,
        setShowCart,
        setCartEnabled,
        setWhatsapp,
        clearCart,
        loadCart,
        setStore,
        setShowStockDialog,
        counter,
        cart: stateCart,
        showCart,
        cartEnabled,
        whatsapp,
        store,
        showStockDialog,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
