import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { gql } from 'apollo-boost';
import I18n from 'i18n-js';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import LabeledValue from '../components/LabeledValue';
import VariationSelector, { Option } from '../components/VariationSelector';
import ZoomableSlider from '../components/ZoomableSlider';
import { CartContext } from '../context/CartContext';
import { MyCatalogGlobalContext, NavigationType } from '../context/GlobalContext';
import { currencyOptions } from '../utils/i18n';
import './productDetail.css';

const GET_PRODUCT = gql`
  query product($id: String!) {
    product(id: $id) {
      id
      title
      description
      price
      oldPrice
      available
      quantity
      deleted
      category {
        id
        description
      }
      variations {
        id
        name
        type
        options {
          id
          name
        }
      }
      selectedOptions {
        id
      }
      optionStock {
        id
        option {
          id
        }
        quantity
      }
      gallery {
        images {
          id
          url
          mediumUrl
          thumbUrl
        }
      }
    }
  }
`;

const ProductDetail = () => {
  let { id, publicId } = useParams<{ id: string; publicId: string }>();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [refetched, setRefetched] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const matches = useMediaQuery('(min-width:600px)');
  const theme = useTheme();
  const {
    setLoading,
    setTitle,
    setNavigationType,
    setBackRoute,
  } = useContext(MyCatalogGlobalContext);
  const {
    setShowCart,
    addProduct,
    loadCart,
    cartEnabled,
    store,
    cart,
  } = useContext(CartContext);
  setNavigationType(NavigationType.BACK);
  setShowCart(true);

  if (history.action === 'PUSH') {
    setBackRoute(null);
  }

  const { data, loading, refetch } = useQuery(GET_PRODUCT, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  });
  if (history.action === 'POP') {
    if (!refetched) {
      setRefetched(true);
      refetch();
    }
    setBackRoute(`/${publicId}`);
  }
  const { price, oldPrice, description, category, title, available, deleted } =
    data?.product || {
      title: '',
      price: '',
      oldPrice: '',
      description: '',
      category: {},
      deleted: false
    };

  if(deleted){
    history.replace('/notfound', {storePublicId: publicId})  
  }

  setLoading(loading);
  setTitle(title);
  if (!Object.keys(cart).length) {
    loadCart(publicId);
  }
  const { images } = data?.product?.gallery || { images: [] };

  return (
    <Box mt={7} mb={2}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box m={matches ? 2 : 0} className="ProductDetail__SliderHolder">
            {!loading && <ZoomableSlider images={images}></ZoomableSlider>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box p={2}>
            {loading && (
              <>
                <Box pb={1}>
                  <Skeleton variant="rect" height={16} animation="wave" />
                </Box>
                <Box pb={1}>
                  <Skeleton variant="rect" height={16} animation="wave" />
                </Box>
                <Skeleton
                  variant="rect"
                  height={16}
                  width="50%"
                  animation="wave"
                />
              </>
            )}
            <LabeledValue
              label={t('Description')}
              value={description}
              textStyle={{ whiteSpace: 'pre-line' }}
            ></LabeledValue>
            {!!price && (
              <LabeledValue
                label={t('Last price')}
                value={I18n.toCurrency(oldPrice, {
                  ...currencyOptions,
                  unit: store?.user?.currency?.symbol || currencyOptions.unit,
                })}
                textStyle={{
                  textDecoration: 'line-through',
                  color: theme.palette.text.secondary,
                }}
              ></LabeledValue>
            )}
            <LabeledValue
              label={t('By')}
              value={I18n.toCurrency(price || oldPrice, {
                ...currencyOptions,
                unit: store?.user?.currency?.symbol || currencyOptions.unit,
              })}
            ></LabeledValue>
            <LabeledValue
              label={t('Category')}
              value={category?.description}
            ></LabeledValue>
            <Box mb={2}>
              <VariationSelector
                variations={data?.product?.variations}
                activeOptions={data?.product?.selectedOptions.map(
                  (op: Option) => op.id
                )}
                onSelect={(selected) => {
                  setSelectedOptions(selected);
                }}
              />
            </Box>
            {title && !available && (
              <Box mt={2} mb={2}>
                <Chip label={t('Out of stock')} />
              </Box>
            )}
            {cartEnabled && (
              <Button
                disabled={!available}
                variant="outlined"
                className="Product-Detail__add-to-cart"
                color="primary"
                onClick={() => {
                  const variationId = Object.values(selectedOptions)
                    .map((option: any) => {
                      return `${data?.product.id}-${option.variationId}-${option.id}`;
                    })
                    .join('');
                  const product = {
                    ...data?.product,
                    stock: data?.product?.quantity,
                  };
                  const optionStock = data?.product?.optionStock.find(
                    (optionWithStock: any) => {
                      return Object.values(selectedOptions).every(
                        ({ id: selectedOptionId }: any) => {
                          return optionWithStock.option.find((option: any) => {
                            return option.id === selectedOptionId;
                          });
                        }
                      );
                    }
                  );
                  delete product.quantity;
                  addProduct(
                    {
                      ...product,
                      id: variationId || data?.product.id,
                      originalId: data?.product.id,
                      options: selectedOptions,
                      stock: optionStock
                        ? optionStock.quantity
                        : data?.product?.quantity,
                      stockOptionId: optionStock ? optionStock.id : null,
                    },
                    publicId
                  );
                }}
              >
                {t('Add to cart')}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default React.memo(ProductDetail);
