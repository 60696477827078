import { Color } from '@material-ui/core';
import { blue, blueGrey } from '@material-ui/core/colors';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { Category } from '../components/Filter';

export enum NavigationType {
  BACK = 'back',
  DRAWER = 'drawer',
  NONE = 'none',
}

export const MyCatalogGlobalContext = createContext<{
  setCustomTheme: React.Dispatch<
    React.SetStateAction<{
      palette: {
        primary: Color;
        secondary: Color;
      };
    }>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setNavigationType: React.Dispatch<React.SetStateAction<NavigationType>>;
  setBackRoute: React.Dispatch<React.SetStateAction<string | null>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCategories: React.Dispatch<React.SetStateAction<Category[]>>;
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  visible: boolean;
  title: string;
  search: string;
  backRoute: string | null;
  navigationType: NavigationType;
  customTheme: any;
  selectedPage: number;
  skip: number;
  selectedCategories: Category[];
  shouldRefetch: boolean;
}>({
  setCustomTheme: () => {},
  setLoading: () => {},
  setTitle: () => {},
  setSearch: () => {},
  setNavigationType: () => {},
  setBackRoute: () => {},
  setVisible: () => {},
  setSelectedPage: () => {},
  setSkip: () => {},
  setSelectedCategories: () => {},
  setShouldRefetch: () => {},
  loading: true,
  title: '',
  search: '',
  backRoute: '',
  navigationType: NavigationType.NONE,
  visible: true,
  customTheme: {},
  selectedPage: 1,
  skip: 0,
  selectedCategories: [],
  shouldRefetch: false,
});
export interface ProviderProps {
  children: ReactNode;
}

export const GlobalProvider = ({ children }: ProviderProps) => {
  const [customTheme, setCustomTheme] = useState({
    palette: {
      primary: blueGrey as Color,
      secondary: blue as Color,
    },
  });

  const [skip, setSkip] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);
  const [title, setTitle] = useState('');
  const [backRoute, setBackRoute] = useState<string | null>('');
  const [navigationType, setNavigationType] = useState<NavigationType>(
    NavigationType.NONE
  );
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <MyCatalogGlobalContext.Provider
      value={{
        setCustomTheme,
        setLoading,
        setTitle,
        setSearch: setSearchValue,
        setNavigationType,
        setBackRoute,
        setVisible,
        setSelectedPage,
        setSkip,
        setSelectedCategories,
        setShouldRefetch,
        loading,
        title,
        search: searchValue,
        backRoute,
        navigationType,
        visible,
        customTheme,
        selectedPage,
        skip,
        selectedCategories,
        shouldRefetch,
      }}
    >
      {children}
    </MyCatalogGlobalContext.Provider>
  );
};
