import { useQuery } from '@apollo/client';
import {
  Box,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import { Pagination } from '@material-ui/lab';
import { gql } from 'apollo-boost';
import I18n from 'i18n-js';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
import { Product } from '../types/product';
import { currencyOptions } from '../utils/i18n';
import { Category } from './Filter';
import GenericError from './GenericError';
import Portal from './Portal';
import ProductSkeleton from './ProductSkeleton';
import SelectVariationDialog from './SelectVariationDialog';
const useStyles = makeStyles((theme) => {
  return {
    media: {
      height: 160,
    },
    priceContainer: {
      marginTop: 'auto',
    },
    price: {
      width: '100%',
      display: 'flex',
      lineHeight: '24px',
      marginTop: 'auto',
    },
    oldPrice: {
      marginLeft: '8px',
      lineHeight: '12px',
      textDecoration: 'line-through',
    },
    card: {
      border: '1px solid #CCC',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    unavailable: {
      '& .media, h6, p': {
        opacity: '0.54',
      },
    },
    title: {
      wordBreak: 'break-word'
    }
  };
});

const GET_PRODUCTS = gql`
  query productList(
    $publicStoreId: String!
    $orderBy: String!
    $skip: Int
    $search: String
    $categories: [String]
  ) {
    productList(
      publicStoreId: $publicStoreId
      orderBy: $orderBy
      skip: $skip
      search: $search
      categories: $categories
    ) {
      total
      products {
        id
        title
        description
        price
        oldPrice
        available
        quantity
        category {
          id
          description
        }
        gallery {
          images {
            id
            url
            thumbUrl
          }
        }
      }
    }
  }
`;
const Products = ({
  orderBy,
  categoriesToFilter,
  showPagination = false,
}: {
  orderBy: string;
  categoriesToFilter: Category[];
  showPagination: boolean;
}) => {
  const {
    setVisible,
    search,
    selectedPage,
    setSelectedPage,
    skip,
    setSkip,
    shouldRefetch,
    setShouldRefetch,
  } = useContext(MyCatalogGlobalContext);
  const { addProduct, setShowCart, cartEnabled, store } =
    useContext(CartContext);
  setShowCart(true);
  const producsListBox = useRef<null | HTMLDivElement>(null);
  const productRef = useRef<null | HTMLDivElement>(null);
  const [selectedProduct] = useState<string>();
  const [productToAdd, setProcutToAdd] = useState<any>();
  const classes = useStyles();
  let { publicId } = useParams<{ publicId: string }>();
  const history = useHistory();
  const selectedCategoryIds = useMemo<string[]>(() => {
    return categoriesToFilter.map((category) => category.id);
  }, [categoriesToFilter]);
  const variables: {
    publicStoreId: string | undefined;
    orderBy: string | undefined;
    skip: number;
    search: string;
    categories: string[] | null;
  } = {
    publicStoreId: publicId,
    orderBy,
    skip,
    search,
    categories: selectedCategoryIds,
  };
  const { data, loading, refetch, error } = useQuery(GET_PRODUCTS, {
    variables,
  });
  useEffect(() => {
    if (shouldRefetch) {
      refetch().finally(() => {
        setShouldRefetch(false);
      });
    }
  }, [refetch, setShouldRefetch, shouldRefetch]);
  const pages = useMemo(() => {
    const pages = data?.productList?.total / 20;
    const roundedUp = Math.ceil(pages);
    if (roundedUp > pages) {
      return roundedUp;
    }
    return pages;
  }, [data]);
  const productList = useMemo(() => {
    return data?.productList?.products;
  }, [data]);
  if (error) {
    return (
      <Box pt={10}>
        <GenericError error={error.graphQLErrors}></GenericError>
      </Box>
    );
  }
  if (productList && productList.length === 0 && categoriesToFilter.length) {
    return (
      <Box p={10}>
        <Typography color="secondary" align="center">
          Nenhum produto para o filtro selecionado.
        </Typography>
      </Box>
    );
  }
  if (productList && productList.length === 0 && search) {
    return (
      <Box p={10}>
        <Typography color="secondary" align="center">
          {'Nenhum resultado para '}
          <b>{search}</b>
        </Typography>
      </Box>
    );
  }
  if (productList && productList.length === 0) {
    return (
      <Box p={10}>
        <Typography color="secondary" align="center">
          Desculpe-nos ainda não adicionamos produtos.
        </Typography>
      </Box>
    );
  }
  if (loading) {
    return (
      <Box mt={2} ml={0}>
        <ProductSkeleton />
      </Box>
    );
  }
  return (
    <Box mt={2} ml={0}>
      <div ref={producsListBox}>
        <Grid container alignContent="space-between" spacing={1}>
          {data &&
            productList.map(
              ({
                id,
                gallery,
                title,
                price,
                oldPrice,
                available,
                quantity,
              }: Product) => (
                <Grid
                  item
                  container
                  xs={6}
                  md={3}
                  sm={4}
                  xl={2}
                  direction="column"
                  key={id}
                >
                  <div
                    className={`${classes.card} ${
                      available ? '' : classes.unavailable
                    }`}
                    ref={id === selectedProduct ? productRef : null}
                    onClick={(clickEvent) => {
                      clickEvent.stopPropagation();
                      clickEvent.preventDefault();
                      setVisible(true);
                      history.push(`/${publicId}/product/${id}`);
                    }}
                  >
                    <CardMedia
                      className={`${classes.media} media`}
                      image={
                        gallery && gallery.images.length
                          ? gallery.images[0].url.startsWith(
                              'https://mycatalog.sfo3.digitaloceanspaces.com'
                            )
                            ? gallery.images[0].thumbUrl ||
                              gallery.images[0].url
                            : `${gallery.images[0].url}/thumb`
                          : '/no-image.png'
                      }
                    />
                    <Box m={1}>
                      <Typography className={classes.title} variant="body2">{title}</Typography>
                    </Box>
                    <Grid
                      container
                      className={classes.priceContainer}
                      justify="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item className={classes.priceContainer}>
                        {!!price && (
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            className={classes.oldPrice}
                          >
                            {I18n.toCurrency(oldPrice, {
                              ...currencyOptions,
                              unit:
                                store?.user?.currency?.symbol ||
                                currencyOptions.unit,
                            })}
                          </Typography>
                        )}
                        <Box m={1} className={classes.price}>
                          <Typography variant="subtitle1">
                            {I18n.toCurrency(price || oldPrice, {
                              ...currencyOptions,
                              unit:
                                store?.user?.currency?.symbol ||
                                currencyOptions.unit,
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        {!available && (
                          <Box mr={2} mb={2} ml={0.5}>
                            <Chip label="Indisponível" size="small" />
                          </Box>
                        )}
                        {cartEnabled && available && (
                          <IconButton
                            onClick={(clickEvent) => {
                              clickEvent.stopPropagation();
                              clickEvent.preventDefault();
                              setProcutToAdd({
                                id,
                                gallery,
                                title,
                                price,
                                oldPrice,
                                stock: quantity,
                              });
                            }}
                          >
                            <AddShoppingCart />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )
            )}
        </Grid>
      </div>
      {pages > 1 && showPagination && (
        <Box pt={2} pb={2}>
          <Grid container justify="center">
            <Pagination
              count={pages}
              color="primary"
              shape="rounded"
              page={selectedPage}
              siblingCount={2}
              onChange={(event: any, page: number) => {
                if (producsListBox?.current) {
                  producsListBox?.current?.scrollIntoView();
                }
                window.scrollTo(0, 0);
                setSelectedPage(page);
                const updatedSkip = page > 1 ? (page - 1) * 20 : 0;
                setSkip(updatedSkip);
                refetch({
                  ...variables,
                  search,
                  categories: selectedCategoryIds,
                  skip: updatedSkip,
                });
              }}
            />
          </Grid>
        </Box>
      )}
      <Portal>
        {productToAdd && (
          <SelectVariationDialog
            title={productToAdd.title}
            productId={productToAdd.id}
            handleClose={() => {
              setProcutToAdd(null);
            }}
            handleConfirm={(selectedOptions, optionStock) => {
              const variationId = Object.values(selectedOptions)
                .map((option: any) => {
                  return `${productToAdd.id}-${option.variationId}-${option.id}`;
                })
                .join('');
              const foundOptionStock = optionStock.find(
                (optionWithStock: any) => {
                  return Object.values(selectedOptions).every(
                    ({ id: selectedOptionId }: any) => {
                      return optionWithStock.option.find((option: any) => {
                        return option.id === selectedOptionId;
                      });
                    }
                  );
                }
              );
              addProduct(
                {
                  ...productToAdd,
                  id: variationId || productToAdd.id,
                  originalId: productToAdd.id,
                  options: selectedOptions,
                  stock: foundOptionStock
                    ? foundOptionStock.quantity
                    : productToAdd.stock,
                  stockOptionId: foundOptionStock ? foundOptionStock.id : null,
                },
                publicId
              );
              setProcutToAdd(null);
            }}
          ></SelectVariationDialog>
        )}
      </Portal>
    </Box>
  );
};
export default React.memo(Products);
