import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Grid,
  Paper,
  Slide,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { gql } from 'apollo-boost';
import React, { useContext, useState } from 'react';
import { useForm, ValidateResult } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
import './RecoverPassword.css';
const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $token: String!) {
    updatePassword(password: $password, token: $token) {
      message
    }
  }
`;

const RecoverPassword = () => {
  const { setLoading } = useContext(MyCatalogGlobalContext);
  const [snackOpen, setSnackOpen] = useState(false);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const { t } = useTranslation();
  let { hash } = useParams();
  setLoading(false);
  const {
    handleSubmit,
    register,
    errors: { password, passwordConfirmation },
    getValues,
  } = useForm();
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      direction="column"
      justify="center"
      className="recover-password__container"
    >
      <Grid item xs={11} lg={4} container direction="column" justify="center">
        <Paper variant="outlined">
          <Box p={2}>
            <form
              action="POST"
              onSubmit={handleSubmit((data, event) => {
                updatePassword({
                  variables: {
                    password: data.password,
                    token: hash,
                  },
                })
                  .then((data) => {
                    setSnackOpen(true);
                    event?.target.reset();
                  })
                  .catch((err) => {
                    console.log();
                  });
              })}
            >
              <Box mb={2}>{t('Change your password')}</Box>

              <TextField
                name="password"
                error={!!password}
                helperText={password && password.message}
                className="input"
                variant="filled"
                label={t('New password')}
                type="password"
                inputRef={register({
                  minLength: {
                    value: 6,
                    message: t(
                      'Your password should be at least 6 digits long'
                    ),
                  },
                  required: t<string>('Required field'),
                })}
              ></TextField>
              <Box mt={2}>
                <TextField
                  name="passwordConfirmation"
                  error={!!passwordConfirmation}
                  helperText={
                    passwordConfirmation && passwordConfirmation.message
                  }
                  className="input"
                  variant="filled"
                  label={t<string>('Confirm your new password')}
                  type="password"
                  inputRef={register({
                    minLength: {
                      value: 6,
                      message: t<string>(
                        'Your password should be at least 6 digits long'
                      ),
                    },
                    validate: (value): ValidateResult => {
                      const values = getValues();
                      return (
                        values.password === value ||
                        t<string>('The password should match')
                      );
                    },
                  })}
                ></TextField>
              </Box>
              <Box
                pt={2}
                mx="auto"
                width="auto"
                display="flex"
                justifyContent="flex-end"
              >
                <Button type="submit" variant="outlined" color="secondary">
                  {t('Change password')}
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </Grid>
      <Snackbar
        open={snackOpen}
        message={t('Your password was changed! PLease login on the app')}
        autoHideDuration={6000}
        TransitionComponent={(props) => (
          <Slide direction="up" {...props}></Slide>
        )}
        onClose={() => {
          setSnackOpen(false);
        }}
      ></Snackbar>
    </Grid>
  );
};

export default RecoverPassword;
