import { Box, Container, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { setLoading } = useContext(MyCatalogGlobalContext);
  const { t } = useTranslation();
  setLoading(false);
  return (
    <Container>
      <Box mt={10} mb={10}>
        <Typography className="title" variant="h3">
          {t('Privacy Policy')}
        </Typography>
        <Typography variant="body1">
          {t(
            'Gurgel tech built the Meu Catálogo app as a Freemium app. This SERVICE is provided by Gurgel tech at no cost and is intended for use as is.'
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.'
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.'
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Meu Catálogo unless otherwise defined in this Privacy Policy.'
          )}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Information Collection and Use')}
        </Typography>
        <Typography variant="body1">
          {t(
            'For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to name, email, CPF. The information that we request will be retained by us and used as described in this privacy policy.'
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'The app does use third-party services that may collect information used to identify you.'
          )}
        </Typography>

        <Typography variant="body1">
          {t(
            'Link to privacy policy of third-party service providers used by the app'
          )}
        </Typography>
        <ul>
          <li>
            <a href="https://www.google.com/policies/privacy/">Google Play Services</a>
          </li>
          <li>
            <a href="https://www.facebook.com/about/privacy/update/printable">Facebook</a>
          </li>
        </ul>

        <Typography className="title" variant="h4">
          {t('Log Data')}
        </Typography>
        <Typography variant="body1">
          {t(
            'We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.'
          )}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Cookies')}
        </Typography>
        <Typography variant="body1">
          {t(
            'Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device\'s internal memory.'
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.'
          )}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Data deletion request')}
        </Typography>
        <Typography variant="body1">
          {t(
            'You have the right to request the deletion of your Personal Information associated with your account. To initiate the data deletion process, simply send an email to atendimento@meucatalogo.app from the email address linked to your account with the subject line "Data Deletion Request." Upon receiving your deletion request, we will verify the authenticity of the email address and proceed with the deletion of your Personal Information from our systems within a reasonable time, as required by applicable law.'
          )}
        </Typography>
        <a
          href={`mailto:atendimento@meucatalogo.app?subject=${t(
            'Data Deletion Request'
          )}`}
        >
          {t('Request your data deletion')}
        </a>
        <Typography className="title" variant="h4">
          {t('Service Providers')}
        </Typography>
        <Typography variant="body1">
          {t(
            'We may employ third-party companies and individuals due to the following reasons:'
          )}
        </Typography>
        <ul>
          <li>{t('To facilitate our Service;')}</li>
          <li>{t('To provide the Service on our behalf;')}</li>
          <li>{t('To perform Service-related services; or')}</li>
          <li>{t('To assist us in analyzing how our Service is used.')}</li>
        </ul>
        <Typography variant="body1">
          {t(
            'We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.'
          )}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Security')}
        </Typography>
        <Typography variant="body1">
          {t(
            'We value your trust in providing us your Personal Information; thus, we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.'
          )}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Links to Other Sites')}
        </Typography>
        <Typography variant="body1">
          {t(
            'This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.'
          )}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Children\'s Privacy')}
        </Typography>
        <Typography variant="body1">
          {t(
            'These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case, we discover that a child under 13 has provided us with personal information; we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.'
          )}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Changes to This Privacy Policy')}
        </Typography>
        <Typography variant="body1">
          {t(
            'We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.'
          )}
        </Typography>

        <Typography variant="body1">
          {t('This policy is effective as of 2020-07-01')}
        </Typography>

        <Typography className="title" variant="h4">
          {t('Contact Us')}
        </Typography>
        <Typography variant="body1">
          {t(
            'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at atendimento@meucatalogo.com.'
          )}
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
