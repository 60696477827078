import {
  Box,
  createStyles,
  Fade,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import WhatsApp from '@material-ui/icons/WhatsApp';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      color: '#FFF',
      padding: 8,
    },
    icons: {
      color: '#FFF',
    },
  })
);

const InfoHeader = ({
  visible = true,
  title = '',
  socialMedia,
  loading = true,
}: {
  socialMedia: any[];
  visible: boolean;
  title: string;
  loading: boolean;
}) => {
  const classes = useStyles();
  if (loading) {
    return <Skeleton variant="rect" height={64} />;
  }

  return (
    <Fade in={visible}>
      <Paper elevation={0} className={classes.paper} square>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justify="space-between"
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item xs={10}>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            {socialMedia &&
              socialMedia.map((social) => (
                <Grid key={social.id} item xs={2} container justify="flex-end">
                  <Link
                    href={`https://wa.me/${social.countryPhoneCode}${
                      social.address
                    }`}
                  >
                    <IconButton aria-label="Contato por whatsapp">
                      <WhatsApp className={classes.icons}></WhatsApp>
                    </IconButton>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Paper>
    </Fade>
  );
};
export default InfoHeader;
