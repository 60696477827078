import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VariationSelector, { Option } from './VariationSelector';

type Props = {
  handleClose: () => void;
  handleConfirm: (selectedOptions: any | null, optionStock: any) => void;
  productId: string;
  title: string;
};
const GET_PRODUCT_VARIATIONS = gql`
  query variationsByProduct($productId: String!) {
    variationsByProduct(productId: $productId) {
      id
      variations {
        id
        name
        type
        options {
          id
          name
        }
      }
      selectedOptions {
        id
      }
      optionStock {
        id
        option {
          id
        }
        quantity
      }
    }
  }
`;

export default function SelectVariationDialog({
  handleClose,
  handleConfirm,
  productId,
  title,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_PRODUCT_VARIATIONS, {
    variables: {
      productId: productId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (
      !loading &&
      data &&
      data.variationsByProduct.selectedOptions.length === 0
    ) {
      handleConfirm([], []);
    }
  }, [loading, data, handleConfirm]);
  const [selectedOptions, setSelectedOptions] = useState({});
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <Box pr={3} pl={3} pb={2}>
          <Typography variant="subtitle1" color="textSecondary">
            {loading
              ? t('Adding to the cart...')
              : t('Select the product variation')}
          </Typography>
        </Box>
        <DialogContent>
          <Box pb={4}>
            {loading && (
              <Grid container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            )}
            <VariationSelector
              xs={12}
              variations={data?.variationsByProduct?.variations}
              activeOptions={data?.variationsByProduct?.selectedOptions.map(
                (op: Option) => op.id
              )}
              onSelect={(selected) => {
                setSelectedOptions(selected);
              }}
            />
          </Box>
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                handleConfirm(
                  selectedOptions,
                  data?.variationsByProduct?.optionStock
                );
              }}
              color="primary"
              autoFocus
            >
              {t('Add to cart')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
