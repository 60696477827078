import { Button, Container, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MyCatalogGlobalContext } from '../context/GlobalContext';
const NotFound = () => {
  const { setLoading, setVisible, setBackRoute } = useContext(MyCatalogGlobalContext);
  const { t } = useTranslation();
  const history = useHistory<{storePublicId?: string}>();
  const { storePublicId } = history.location?.state || {storePublicId: null}
  setLoading(false);
  setVisible(true)

  if(storePublicId){
    setBackRoute(`/${storePublicId}`)
  }
  return (
    <Container>
      <Grid container alignItems="center" style={{ height: '80vh' }}>
        <Grid
          item
          justify="center"
          alignItems="center"
          container
          direction="column"
        >
          <Grid item>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {t('Sorry, we did not find what you are looking for.')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setVisible(false)
                if(storePublicId){
                  return history.replace(`/${storePublicId}`)
                }
                return history.goBack();
              }}
            >
              {t('Go back')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
