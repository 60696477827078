import {
  CircularProgress,
  Fade,
  Modal,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import './zoomableSlider.css';

type Image = {
  url: string;
  mediumUrl: string;
};

const ZoomableSlider = ({ images }: { images: Image[] }) => {
  const matches = useMediaQuery('(min-width:600px)');
  const slider = useRef<Slider>(null);
  const [initialSlide, setInitialSlide] = useState(0);
  const sliderSettings: Settings = {
    dots: true,
    slidesToShow: 1,
    arrows: matches,
    initialSlide: initialSlide,
    lazyLoad: 'progressive',
  };
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <Slider
        {...sliderSettings}
        className="ProductDetail__Slider"
        ref={slider}
      >
        {images &&
          images.map((img, index: number) => {
            return (
              <div
                onClick={(pa) => {
                  setImageLoading(true);
                }}
                key={index}
                className="ProductDetail__Slider__ImageContainer"
              >
                <img
                  src={
                    img.url.startsWith(
                      'https://mycatalog.sfo3.digitaloceanspaces.com'
                    )
                      ? img.mediumUrl || img.url
                      : `${img.url}/medium`
                  }
                  alt={`Imagem ${index + 1}`}
                  onClick={() => {
                    setInitialSlide(index);
                    setModalIsOpen(true);
                  }}
                />
              </div>
            );
          })}
        {images && images.length === 0 && (
          <div className="ProductDetail__NoImage"></div>
        )}
      </Slider>
      <Modal open={modalIsOpen} style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
        <Fade in={modalIsOpen}>
          <div className="ImageContainer--zoom">
            <CloseIcon
              onClick={() => {
                setModalIsOpen(false);
              }}
              className="ImageContainer__close"
              color="inherit"
              aria-label="menu"
              htmlColor="#FFF"
            ></CloseIcon>
            {imageLoading && (
              <CircularProgress className="ImageContainer__loader"></CircularProgress>
            )}
            <Slider
              {...sliderSettings}
              initialSlide={initialSlide}
              className="ProductDetail__Slider"
            >
              {images &&
                images.map((img: { url: string }, index: number) => {
                  return (
                    <div
                      key={index}
                      className="ProductDetail__Slider__ImageContainer"
                    >
                      <img
                        src={
                          img.url.startsWith(
                            'https://mycatalog.sfo3.digitaloceanspaces.com'
                          )
                            ? img.url
                            : `${img.url}/original`
                        }
                        alt={`Imagem ${index + 1}`}
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ZoomableSlider;
