import { useCallback, useState } from 'react';

const useClipboard = (): {
  hasCopied: boolean;
  copy: (text: string) => void;
} => {
  const [hasCopied, setHasCopied] = useState(false);

  const copy = useCallback((text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setHasCopied(true);
    });
  }, []);

  return {
    hasCopied,
    copy,
  };
};

export default useClipboard