import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const StepperSkeleton = () => {
  return (
    <Box display="flex" flexDirection={'column'} p={2} alignItems={'stretch'}>
      <Skeleton height={24} width={180} />
      <Skeleton height={56}/>
      <Skeleton height={56}/>
      <Skeleton height={56}/>
      <Skeleton height={56} width={180} style={{alignSelf:'flex-end'}}/>
    </Box>
  )
}

export default StepperSkeleton