import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: {
      width: '100%',
    },
  })
);

export type Category = {
  id: string;
  name: string;
  checked: boolean;
};

export interface FilterProps {
  categories: Category[];
  onFilterSelected: (categories: Category[]) => void;
}

const Filter: React.FC<FilterProps> = ({ categories, onFilterSelected }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const [filterCategories, setFilterCategories] = useState<Category[]>([]);
  useEffect(() => {
    setFilterCategories(categories);
  }, [categories]);

  const onFromChange = useCallback(
    (event) => {
      const changedCategories = filterCategories.map((category) => {
        if (category.id === event.target.name) {
          return {
            ...category,
            checked: event.target.checked,
          };
        }
        return category;
      });
      onFilterSelected(changedCategories);
    },
    [filterCategories, onFilterSelected]
  );
  return (
    <Box className={classes.filter}>
      <Box mt={isMobile ? 7 : 9} mb={1}>
        <Typography variant="subtitle2">{t('Filter by category')}</Typography>
      </Box>
      <FormGroup onChange={onFromChange}>
        <Grid container direction="column">
          {filterCategories.map((category) => (
            <Grid item key={category.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={category.checked}
                    name={category.id}
                  />
                }
                label={category.name}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Box>
  );
};
export default Filter;
