import { useQuery } from '@apollo/client';
import { Box,Button,Container,Typography } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import I18n from 'i18n-js';
import { GET_ORDER_PAYMENT } from 'my-catalog-shared/graphql/queries';
import { orderPayment } from 'my-catalog-shared/graphql/types/orderPayment';
import React,{ useCallback,useContext,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory,useParams } from 'react-router';
import PaymentTypeMercadoPago from '../../components/PaymentTypeMercadoPago';
import PaymentTypePix from '../../components/PaymentTypePix';
import { CartContext } from '../../context/CartContext';
import { MyCatalogGlobalContext,NavigationType } from '../../context/GlobalContext';
import { isIos } from '../../utils/detect-platform';
import { currencyOptions } from '../../utils/i18n';


const PAYMENT_TYPE_COMPONENT = {
  'PIX': PaymentTypePix,
  'MERCADO_PAGO': PaymentTypeMercadoPago,
  'TO_BE_ARRANGED': null,
  'TRANSFER': null,
} as const

const OrderPayment = () => {
  const history = useHistory<{ message?: string; storePublicId: string }>();

  const { publicId, orderId } =
    useParams<{ publicId: string; orderId: string }>();
  const { data, loading, error } = useQuery<orderPayment>(GET_ORDER_PAYMENT, {
    variables: { orderId },
  });

  if (error?.message === 'payment_not_found') {
    history.push('/notfound', { storePublicId: publicId });
  }

  const whatsAppLink = useRef<null | HTMLAnchorElement>(null);
  const { t } = useTranslation();
  const {
    setLoading,
    setTitle,
    setNavigationType,
    setVisible,
    setShouldRefetch,
  } = useContext(MyCatalogGlobalContext);
  const { setShowCart, whatsapp, store } = useContext(CartContext);

  setLoading(false);
  setNavigationType(NavigationType.BACK);
  setShowCart(false);
  setTitle(t('Order payment'));
  setVisible(true);

  const whatsAppText = useCallback(() => {
    if (!history.location.state) {
      return t(
        'Hi my name is {{name}}, I would like to confirm the payment of my order.',
        { name: data?.orderPayment?.customerName }
      );
    }
    const { message } = history.location.state;
    return message || '';
  }, [history.location.state, data, t]);

  const PaymentComponent =
    PAYMENT_TYPE_COMPONENT[
      data?.orderPayment?.paymentOption?.paymentService?.type || 'TO_BE_ARRANGED'
    ];

  return (
    <Container maxWidth="sm">
      <Box
        mt={10}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {loading ? (
          <>
            <Skeleton height={48} width="100%" animation="wave" />
            <Skeleton height={300} width="100%" animation="wave" />
            <Skeleton height={48} width={200} animation="wave" />
          </>
        ) : (
          <>
            <Box mb={2}>
              <Typography variant="h6">
                {t('Payment method')} 
              </Typography>
            </Box>
            {PaymentComponent && data?.orderPayment ? (
              <PaymentComponent
                pixPayload={data?.orderPayment?.pixPayload || ''}
                amount={I18n.toCurrency(data?.orderPayment?.amount || 0, {
                  ...currencyOptions,
                  unit: store?.user?.currency?.symbol || currencyOptions.unit,
                })}
                publicKey={data?.orderPayment?.mercadoPago?.publicKey}
                preferenceId={data?.orderPayment?.mercadoPago?.preferenceId}
              />
            ) : null}

            <Box
              mt={2}
              mb={2}
              fontStyle="body1"
              color="text.secondary"
              textAlign="center"
            >
              {whatsapp &&
                t(
                  'After the payment please send the order to negociate further the details.'
                )}
            </Box>
            {whatsapp && (
              <Box pt={2}>
                <Button
                  type="submit"
                  color="primary"
                  innerRef={whatsAppLink}
                  onClick={() => {
                    history.goBack();
                    setShouldRefetch(true);
                  }}
                  variant="outlined"
                  startIcon={<WhatsApp />}
                  target={isIos() ? '' : '_blank'}
                  href={`https://wa.me/${whatsapp?.countryPhoneCode}${
                    whatsapp?.address
                  }?text=${encodeURIComponent(whatsAppText())}`}
                >
                  {history.location.state
                    ? t('Send order')
                    : t('Send payment confirmation')}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};
export default OrderPayment;
