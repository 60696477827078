import { Box, Card, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const ProductSkeleton = () => {
  return (
    <Grid container spacing={1}>
      {new Array(20).fill(0).map((value, index) => (
        <Grid
          key={index}
          item
          container
          xs={6}
          md={3}
          sm={4}
          xl={2}
          direction="column"
        >
          <Card>
            <Skeleton variant="rect" height={126} animation="wave" />
            <Box p={1}>
              <Box mb={2}>
                <Skeleton variant="text" animation="wave" />
              </Box>
              <Box mb={1}>
                <Skeleton variant="text" animation="wave" />
              </Box>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductSkeleton;
