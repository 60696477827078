import { useLazyQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  createStyles,
  debounce,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { FIND_ADDRESS } from 'my-catalog-shared/graphql/queries';
import {
  findAddress,
  findAddressVariables,
} from 'my-catalog-shared/graphql/types/findAddress';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import { COUNTRIES } from '../constants';
import { CartForm } from '../screens/Cart';
import { brazilStates } from '../utils/i18n';

const AddressForm = () => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const zipcodeMask =
    COUNTRIES.find((country) => country.languages.includes(language))
      ?.zipCodeMask ||
    COUNTRIES.find((country) => country.languages.includes('en'))?.zipCodeMask;

  const [refresh, setRefresh] = useState(false);
  const { errors, register, getValues, reset, setError, control, setValue } =
    useFormContext<CartForm>();

  const [findAddress, { loading, data }] = useLazyQuery<
    findAddress,
    findAddressVariables
  >(FIND_ADDRESS, {
    fetchPolicy: 'network-only',
  });
  const defaults = getValues();
  useEffect(() => {
    return () => {
      reset({ ...getValues() });
    };
  }, [reset, getValues]);

  useEffect(() => {
    if (data?.findAddress) {
      const address = data?.findAddress;
      const values = getValues();
      reset(
        {
          ...values,
          address: {
            city: address.city,
            neighborhood: address.neighborhood || undefined,
            state: address.state as string,
            street: address.street,
            zipcode: address.zipcode,
            streetNumber: values.address?.streetNumber,
            apartmentNumber: values.address?.apartmentNumber,
          },
        },
        { isValid: true }
      );
      if (!values.address?.streetNumber) {
        setError('address.streetNumber', { type: 'required' });
      }
    }
    setRefresh(!refresh);

    // eslint-disable-next-line
  }, [loading, data?.findAddress, getValues, reset, setError]);

  const debounceSearchZipcode = debounce((value: string) => {
    if (value.length < 9) return;

    findAddress({
      variables: {
        zipcode: value,
      },
    });
    setValue('address.zipcode', value)
  }, 400);

  return (
    <Box marginX={2}>
      <Box mb={2} mt={2} display={'flex'}>
        <FormControl variant="outlined" className={classes.input}>
          <InputLabel htmlFor="address.zipcode">{t('Zipcode')}</InputLabel>
          <OutlinedInput
            defaultValue={defaults.address?.zipcode}
            className={classes.input}
            inputRef={register()}
            onChange={(event) => {
              debounceSearchZipcode(event.target.value);
            }}
            inputComponent={(props) => {
              const { inputRef, ...other } = props;
              return (
                <MaskedInput
                  {...other}
                  guide={false}
                  ref={(ref: any) => {
                    inputRef(ref ? ref.inputElement : null);
                  }}
                  mask={zipcodeMask}
                />
              );
            }}
            label={t('Zipcode')}
            name="address.zipcode"
            id="address.zipcode"
            endAdornment={
              <IconButton>
                {!loading && <Search />}
                {loading && <CircularProgress size={24} />}
              </IconButton>
            }
          />
        </FormControl>
      </Box>
      <Box mb={2} mt={2}>
        <TextField
          key={refresh ? 1 : 2}
          defaultValue={defaults.address?.street}
          className={classes.input}
          error={!!errors?.address?.street}
          helperText={
            errors?.address?.street && (errors?.address?.street as any).message
          }
          inputRef={register({
            required: t<string>('Street is required'),
          })}
          label={t('Street')}
          variant="outlined"
          name="address.street"
        />
      </Box>
      <Box mb={2} mt={2} display={'flex'}>
        <TextField
          defaultValue={defaults.address?.streetNumber}
          className={classes.input}
          error={!!errors?.address?.streetNumber}
          helperText={
            errors?.address?.streetNumber &&
            (errors?.address?.streetNumber as any).message
          }
          inputRef={register({
            required: t<string>('House/building number is required'),
          })}
          label={t('House/buld. number')}
          variant="outlined"
          name="address.streetNumber"
        />
        <TextField
          style={{ marginLeft: '16px' }}
          defaultValue={defaults.address?.apartmentNumber}
          className={classes.input}
          error={!!errors?.address?.apartmentNumber}
          helperText={
            errors?.address?.apartmentNumber &&
            (errors?.address?.apartmentNumber as any).message
          }
          inputRef={register}
          label={t('Apartament number')}
          variant="outlined"
          name="address.apartmentNumber"
        />
      </Box>
      <Box mb={2} mt={2}>
        <TextField
          key={refresh ? 3 : 4}
          defaultValue={defaults.address?.neighborhood}
          className={classes.input}
          inputRef={register()}
          label={t('Neighborhood')}
          variant="outlined"
          name="address.neighborhood"
        />
      </Box>
      <Box mb={2} mt={2} display={'flex'} alignItems={'center'}>
        {brazilStates && (
          <Box mr={2}>
            <FormControl variant="outlined">
              <InputLabel style={{ background: '#fff' }} id="address.state">
                {t('State')}
              </InputLabel>
              <Controller
                name="address.state"
                defaultValue={defaults.address?.state || 'SP'}
                control={control}
                render={(props) => {
                  return (
                    <Select
                      key={refresh ? 9 : 10}
                      labelId="address.state"
                      style={{ width: '94px' }}
                      {...props}
                    >
                      {brazilStates?.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              ></Controller>
            </FormControl>
          </Box>
        )}
        <TextField
          key={refresh ? 5 : 6}
          defaultValue={defaults.address?.city}
          className={classes.input}
          error={!!errors?.address?.city}
          helperText={
            errors?.address?.city && (errors?.address?.city as any).message
          }
          inputRef={register({
            required: t<string>('City is required'),
          })}
          label={t('City')}
          variant="outlined"
          name="address.city"
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartContainer: {
      marginTop: '68px',
      padding: 0,
    },
    cartContainerDesktop: {
      marginTop: '84px',
      padding: 0,
    },
    input: {
      width: '100%',
    },
    sendOrder: {
      float: 'right',
    },
    totalLine: {
      background: '#eee',
    },
  })
);

export default AddressForm;
