import {
  FormControl,
  Grid,
  GridSize,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
export type Option = {
  id: string;
  name?: string;
};
type Variation = {
  id: string;
  name: string;
  type: string;
  options: [Option];
};

interface Props {
  variations: [Variation];
  activeOptions: [String] | null;
  onSelect: (selectedOptions: any) => void;
  xs?: GridSize;
}

const VariationSelector = ({
  variations,
  onSelect,
  activeOptions = null,
  xs = 6,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<any>(null);
  const handleVariationChange = (option: any, variation: any) => {
    const selected = {
      ...selectedOptions,
      [variation.id]: {
        variationName: variation.name,
        variationId: variation.id,
        ...option,
      },
    };
    onSelect(selected);
    setSelectedOptions(selected);
  };
  const initialSelection = useMemo(() => {
    if (!variations) return null;
    let selected = {};
    variations?.forEach((variation) => {
      if (!variation.options || !variation.options.length) return;
      const firstSlection = variation.options.filter((op) =>
        activeOptions?.includes(op.id)
      );
      const firstOption = firstSlection.length
        ? firstSlection[0]
        : variation.options[0];
      selected = {
        ...selected,
        [variation.id]: {
          variationName: variation.name,
          variationId: variation.id,
          ...firstOption,
        },
      };
    });
    return selected;
  }, [variations, activeOptions]);
  useEffect(() => {
    onSelect(initialSelection);
    setSelectedOptions(initialSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variations]);
  if (!activeOptions?.length) {
    return null;
  }
  return (
    <Grid container direction="column" spacing={3}>
      {variations &&
        variations.length > 0 &&
        variations.map((variation: Variation) => {
          if (!variation.options.length) {
            return null;
          }
          const options =
            variation.options &&
            variation.options.filter((op: Option) => {
              return activeOptions!.includes(op.id);
            });
          return (
            <Grid item xs={xs} key={variation.id}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{variation.name}</InputLabel>
                <Select
                  value={
                    (selectedOptions && selectedOptions[variation.id]?.id) || ''
                  }
                  label={variation.name}
                  onChange={(ev) => {
                    const target: HTMLElement = ev.nativeEvent
                      .srcElement as HTMLElement;
                    handleVariationChange(
                      {
                        id: ev.target.value,
                        name: target?.innerText,
                      },
                      variation
                    );
                  }}
                >
                  {options.map(({ id, name }: any) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default VariationSelector;
