import { useLazyQuery } from '@apollo/client';
import { GET_STORE } from 'my-catalog-shared/graphql/queries';
import { store, store_store } from 'my-catalog-shared/graphql/types/store';
import React, { createContext, useEffect, useState } from 'react';
import { USER_KEY } from '../constants';
import { ProviderProps } from './GlobalContext';

type User = {
  id: string;
  paid: boolean;
  currency: {
    code: string;
    delimiter: string;
    separator: string;
    symbol: string;
  };
  roles: ['SUPER_ADMIN']
};

interface PontextValue {
  selectedStore: store_store | null | undefined;
  storeIsLoading: boolean;
  selectStoreOpen: boolean;
  hasStores: boolean;
  user: User | null;
  getStore: (storeId: string) => void;
  setSelectStoreOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHasStores: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedStore: React.Dispatch<
    React.SetStateAction<store_store | undefined>
  >;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  getUser: () => User | null;
}
export const ManagementContext = createContext<PontextValue>({
  selectedStore: null,
  storeIsLoading: false,
  selectStoreOpen: false,
  hasStores: true,
  user: null,
  getStore: () => {},
  setSelectStoreOpen: () => {},
  setHasStores: () => {},
  setSelectedStore: () => {},
  setUser: () => {},
  getUser: () => null,
});

export const ManagementProvider = ({ children }: ProviderProps) => {
  const [selectStoreOpen, setSelectStoreOpen] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [hasStores, setHasStores] = useState(true);
  const [selectedStore, setSelectedStore] =
    useState<store_store | undefined>(undefined);
  const getUser = () => {
    const localStorageUser = localStorage.getItem(USER_KEY);
    return localStorageUser ? JSON.parse(localStorageUser) : null;
  };
  const [getStore, { data, loading }] = useLazyQuery<store>(GET_STORE, {
    fetchPolicy: 'network-only'
  });
  const lazyGetStore = (storeId: string) => {
    setSelectStoreOpen(false);
    getStore({ variables: { publicId: storeId } });
  };
  useEffect(() => {
    if (data?.store) {
      setSelectedStore(data?.store);
    }
  }, [data]);
  return (
    <ManagementContext.Provider
      value={{
        getStore: lazyGetStore,
        storeIsLoading: loading,
        selectedStore: selectedStore,
        selectStoreOpen,
        hasStores,
        user,
        setSelectStoreOpen,
        setHasStores,
        setSelectedStore,
        setUser,
        getUser,
      }}
    >
      {children}
    </ManagementContext.Provider>
  );
};
